/*---  Main-menu  ---*/

.main-menu-sub {
  font-size: 13px;
  margin-left: 32px;
  margin-top: 12px;
  display: none;

  .nav {
    flex-direction: column;
  }

  .nav-link {
    padding: 0;
    display: block;
    color: #596882;

    + .nav-link {
      margin-top: 6px;
      padding-top: 6px;
      border-top: 1px dotted$border;
    }

    &:hover, &:focus {
      color: $primary;
    }

    &.active {
      color: $primary;
      font-weight: 500;
    }
  }
}

.main-menu-sub-mega {
  left: 0;
  right: 0;
  background-color: transparent;
  padding: 0;
  border: 0;

  .container {
    padding: 0;

    > div + div {
      margin-top: 25px;
    }
  }

  .nav {
    min-width: 120px;
    flex-direction: column;

    + .nav {
      border-top: 1px dotted$border;
      margin-top: 6px;
      padding-top: 6px;
    }
  }

  .mega-subtitle {
    display: block;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    color: $color;
    letter-spacing: .5px;
    margin-bottom: 15px;
    margin-top: 30px;
  }

  .nav > span:first-of-type {
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .main-menu-sub {
    position: absolute;
    top: 74px;
    background-color: $white;
    padding: 15px 20px;
    border: 2px solid rgba(28, 39, 60, 0.12);
    min-width: 200px;
    margin-left: 0;
    margin-top: 0;

    .nav-link.active {
      font-weight: 400;
    }
  }

  .main-menu-sub-mega {
    .container {
      display: flex;
      background-color: $white;
      border: 2px solid rgba(28, 39, 60, 0.12);

      > div {
        display: flex;
        padding: 20px;
        flex: 1;
        margin-top: 0;

        + div {
          margin-top: 0;
          border-left: 1px solid rgba(28, 39, 60, 0.05);
        }
      }
    }

    .nav {
      + .nav {
        margin-left: 20px;
        margin-top: 31px;
        padding-top: 0;
        border-top: 0;
      }

      > span {
        font-size: 12px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .main-menu-sub-mega {
    .container > div {
      padding: 20px 25px;
    }

    .nav {
      min-width: 140px;

      + .nav {
        margin-left: 25px;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .main-menu-sub-mega .container {
    max-width: none;
  }
  .main-navbar-show .main-navbar {
    transform: translateX(0);
    visibility: visible;
  }
  .main-navbar>.container, .main-navbar>.container-fluid {
    padding: 0;
  }
  .main-navbar-show .main-navbar-backdrop {
    visibility: visible;
    opacity: 1;
  }
}



@media (min-width: 992px){
	.main-navbar .nav-sub1 {
		position: absolute;
		left: 180px;
		top: 0px;
		width: 200px;
		background-color: #ffffff;
		border: 1px solid transparent;
		border-top: 1px solid #e1e6f1;
		padding: 8px 20px;
		z-index: 900;
		box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
	}
	.main-navbar .nav-sub2 {
		position: absolute;
		left: 180px;
		top: 0px;
		width: 200px;
		background-color: #ffffff;
		border: 1px solid transparent;
		border-top: 1px solid #e1e6f1;
		padding: 8px 20px;
		z-index: 900;
		box-shadow:  0 8px 16px 0 rgba(162, 169, 204, 0.24);
	}
	.main-navbar .nav-sub1 {
		padding:8px 20px !important;
	}
	.main-navbar .nav-sub2 {
		padding:8px 20px !important;
	}
	.main-navbar .nav-sub-link.with-sub1::after {
		content: '\f3d1' !important;
		font-family: 'Ionicons';
	}
	.main-navbar .nav-sub-link.with-sub2::after {
		content: '\f3d1' !important;
		font-family: 'Ionicons';
	}
}

@media (min-width: 992px){
	.dark-theme{
	.main-navbar .nav-sub1 {
		position: absolute;
		left: 180px;
		top: 0px;
		width: 200px;
		background-color: #1c1c38;
		border: 1px solid transparent;
		border-top: 1px solid #282842;
		padding: 8px 20px;
		z-index: 900;
		box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
	}
	.main-navbar .nav-sub2 {
		position: absolute;
		left: 180px;
		top: 0px;
		width: 200px;
		background-color: #1c1c38;
		border: 1px solid transparent;
		border-top: 1px solid #282842;
		padding: 8px 20px;
		z-index: 900;
		box-shadow:  0 8px 16px 0 rgba(162, 169, 204, 0.24);
	}
	.main-navbar .nav-sub1 {
		padding:8px 20px !important;
	}
	.main-navbar .nav-sub2 {
		padding:8px 20px !important;
	}
	.main-navbar .nav-sub-link.with-sub1::after {
		content: '\f3d1' !important;
		font-family: 'Ionicons';
	}
	.main-navbar .nav-sub-link.with-sub2::after {
		content: '\f3d1' !important;
		font-family: 'Ionicons';
	}
}
}
.main-navbar .nav-item .nav-sub-item.show>.nav-sub1 {
    display: block;
}
.main-navbar .nav-sub1 {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
}
.main-navbar .nav-sub-link {
    padding: 0;
    color: #334151;
    font-size: .8125rem;
    font-weight: 400;
    position: relative;
    display: flex;
    align-items: center;
    height: 42px;
    outline: none;
}
.main-navbar .nav-sub-link.with-sub1::after {
    content: '\f3d0';
    font-family: 'Ionicons';
    font-size: 11px;
    font-weight: 400;
    display: inline-block;
    position: relative;
    margin-left: auto;
    color: #a8afc7;
}

.main-navbar .nav-item .nav-sub-item.show>.nav-sub2 {
    display: block;
}
.main-navbar .nav-sub2 {
    display:none;
    list-style: none;
    padding:0;
    margin: 0;
}
.main-navbar .nav-sub-link.with-sub2::after {
    content: '\f3d0';
    font-family: 'Ionicons';
    font-size: 11px;
    font-weight: 400;
    display: inline-block;
    position: relative;
    margin-left: auto;
    color: #a8afc7;
}