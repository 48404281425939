/*---  Custom-styles  ---*/

body {
    background: #eff1f9;
    color: #334151;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    -webkit-font-feature-settings: "liga"0;
    font-feature-settings: "liga"0;
    height: 100%;
    overflow-y: scroll;
    position: relative;
}

.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

*::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    transition: .3s background;
}

::-webkit-scrollbar-thumb {
    background: #e1e6f1;
}

*:hover::-webkit-scrollbar-thumb {
    background: #adb5bd;
}

.box-shadow {
    box-shadow: 0 8px 16px 0 rgba(162, 169, 204, .24);
}

.box-shadow-0 {
    box-shadow: none !important;
}

.custom-card {
    margin-bottom: 20px;
    border: 0;
    box-shadow: 0 8px 16px 0 rgba(162, 169, 204, .24);
}

.card-aside {
    -ms-flex-direction: row;
    flex-direction: row;
}

.card-aside-column {
    min-width: 5rem;
    width: 30%;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    background: no-repeat center/cover;
}

.card-options {
    margin-left: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-order: 100;
    order: 100;
    margin-right: -.5rem;
    color: #99a6b7;
    -ms-flex-item-align: center;
    align-self: center;
}

.card-options a:not(.btn) {
    margin-left: .5rem;
    color: #99a6b7;
    display: inline-block;
    min-width: 1rem;
}

.card-options a:not(.btn):hover {
    text-decoration: none;
    color: #334151;
}

.card-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    margin: 0;
}

.card-collapsed> :not(.card-header):not(.card-status) {
    display: none;
}

.card-options a:not(.btn) i {
    font-size: 1rem;
    vertical-align: middle;
}

.card-options .dropdown-toggle:after {
    display: none;
}

.cover-image {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover !important;
}

.custom-card-header {
    background: #fff;
    display: flex;
    line-height: 0;
}

.header-brand-img {
    height: 3rem;
    line-height: 2.5rem;
    vertical-align: middle;
    width: auto;
    margin-top: 4px;
}

.card-body+.card-body {
    border-top: 1px solid #e1e6f1;
}

.header-navheading {
    padding: 20px 20px 15px;
    text-align: center;
}
.main-navbar .nav-sub-link:before {
    content: '\e936';
    font-family: 'feather';
    margin-right: 8px;
    color: #334151;
    font-size: 8px;
}

.page-header .breadcrumb {
    background: none;
    padding-left: 0 !important;
    padding: 0;
    margin-bottom: 0;
}

.page-header .breadcrumb-item a {
    color: #334151;
}

.page-header .breadcrumb-item.active {
    color: #33689C;
}

.page-header {
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin: 1.5rem 0 1.5rem;
    -ms-flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    position: relative;
    min-height: 50px;
}

.card-sub-title {
    font-size: 13px;
    margin-top: -4px;
}

.user-card .main-img-user {
    margin: 0 auto;
}
.flot-chart {
    width: 100%;
    height: 180px;
}

.flot-chart1 {
    width: 100%;
    height: 300px;
}

.flot-chart-wrapper {
    position: relative;
    margin-left: -16px;
    margin-right: -30px;
}

.chart-wrapper {
    position: absolute;
    right: -8px;
    bottom: 5px;
    left: -8px;
}

.dash-20 {
    font-size: 20px;
}

.dash-25 {
    font-size: 25px;
}

.dash-30 {
    font-size: 30px;
}

.fs-10 {
    font-size: 10px;
}

.fs-11 {
    font-size: 11px;
}

.fs-12 {
    font-size: 12px;
}

.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
}

.fs-17 {
    font-size: 17px;
}

.fs-18 {
    font-size: 18px;
}

.fs-19 {
    font-size: 19px;
}

.fs-20 {
    font-size: 20px;
}

.fs-30 {
    font-size: 30px;
}

.fs-40 {
    font-size: 40px;
}

.fs-50 {
    font-size: 50px;
}

#chartBar5 {
    height: 135px;
}

.flag-icon {
    font-size: 24px;
    line-height: 1;
    border-radius: 100%;
}

/* Back-top-button*/
#back-to-top {
    color: #fff;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99;
    display: none;
    text-align: center;
    z-index: 10000;
    height: 30px;
    width: 30px;
    background-repeat: no-repeat;
    border: 2px solid transparent;
    background: #33689C;
    background-position: center;
    transition: background-color 0.1s linear;
    -moz-transition: background-color 0.1s linear;
    -webkit-transition: background-color 0.1s linear;
    -o-transition: background-color 0.1s linear;
}

#back-to-top i {
    padding-top: 6px;
    font-size: 14px;
    line-height: 1.8rem;
}

#back-to-top:hover {
    color: #33689C !important;
    border: 2px solid #33689C;
    background: #fff !important;
}

.contact-icon {
    display: inline-flex;
    width: 35px;
    height: 35px;
    text-align: center;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.contact-icon:hover {
    background: #e1e6f1;
}

.accordion-color .card-header a,
.accordion-color .card-header a:hover,
.accordion-color .card-header a:focus {
    color: #33689C;
}

.accordion-color .card-header a.collapsed {
    background-color: #33689C;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 500;
    letter-spacing: -.1px;
}

.accordion-color .card-header a.collapsed:hover,
.accordion-color .card-header a.collapsed:focus {
    background-color: #33689C;
    color: #fff;
}

.custom-leftnav .main-content-left-components {
    position: -webkit-sticky;
    position: sticky;
    border-right: 0;
    top: 4rem;
}

.component-item a:before {
    content: '\e92f';
    margin-right: 8px;
    position: relative;
    font-family: 'feather' !important;
    color: #68798b;
}

.square {
    border-radius: 0 !important;
}

.radius {
    border-radius: 5px !important;
}

.icons-list {
    list-style: none;
    margin: 0 -1px -1px 0;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.icons-list-item {
    flex: 1 0 2rem;
    height: 2rem;
    text-align: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.icons-list .flag-icon {
    border-radius: 0;
}

.description-block {
    display: block;
    margin: 10px 0;
    text-align: center;
}

.skill-tags a {
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 5px 10px;
    border-radius: 0px;
    color: #99a6b7;
    font-size: 12px;
    border: 1px solid #e1e6f1;
}

.reviewnavs {
    padding-left: 0;
    list-style: none;
    display: inline-flex;
}

.reviewnavs li {
    margin-right: 5px;
}

.reviewnavs li a {
    color: #99a6b7;
}

.header-brand-img.icon-logo {
    display: none;
}

.main-header-right .nav-link.icon {
    font-size: 20px;
    color: #1c273c;
    cursor: pointer;
    line-height: .9;
}

.dash-icon {
    position: absolute;
    right: 10px;
    font-size: 3rem;
    opacity: 0.5;
    color: #d3d9e4;
    top: 10px;
}

.sidebar-body h5 {
    padding: 10px 10px;
    font-size: 14px;
    border-bottom: 1px solid #e1e6f1;
}

.price-img {
    padding-top: 2rem;
}

.price-img i {
    font-size: 3rem;
}

.price-1 .list-unstyled li {
    padding: 7px 0;
}

.card-pricing h1 {
    font-size: 2.5rem !important;
    padding: 15px 30px;
    text-align: center;
}

.pricing-title {
    text-align: center;
    padding-top: 20px;
    font-size: 20px;
    font-weight: 500;
}

.card-pricing2 h1 {
    font-size: 2.5rem !important;
    padding: 15px 30px;
    text-align: center;
}

.card-pricing3 h1 {
    font-size: 2.5rem !important;
    padding: 15px 30px;
    text-align: center;
}

.card-pricing4 h1 {
    font-size: 2.5rem !important;
    padding: 15px 30px;
    text-align: center;
}

.card-pricing .list-unstyled li {
    padding: .5rem 0;
    border-bottom: 0;
}

.card-pricing2 .list-unstyled li {
    padding: .5rem 0;
    border-bottom: 0;
}

.card-pricing3 .list-unstyled li {
    padding: .5rem 0;
    border-bottom: 0;
}

.card-pricing4 .list-unstyled li {
    padding: .5rem 0;
    border-bottom: 0;
}

.pricing-tabs .pri-tabs-heading {
    padding: 15px;
    text-align: center;
    margin: 0 auto;
    display: inline-table;
}

.pricing-tabs .nav-price li a {
    padding: 10px 20px 10px 20px;
    color: #1c273c;
    background: #fff;
    border: 1px solid #e1e6f1;
}

.pricing-tabs .nav-price li .active {
    background: #e1e6f1;
    color: #1c273c;
}

.pricing-tabs .tab-content {
    padding: 15px;
    margin-top: 1.5rem
}

.pricing1 .price {
    font-size: 2.5rem;
}

/* Timeline */
/*--- Vertical --*/
.vtimeline {
    list-style: none;
    padding: 0;
    position: relative;
    margin-bottom: 20px;
}

.vtimeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: #d1d4e4;
    left: 50%;
    margin-left: -1.5px;
}

.vtimeline .timeline-wrapper {
    display: block;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    padding-right: 90px;
}

.vtimeline .timeline-wrapper:before {
    content: " ";
    display: table;
}

.vtimeline .timeline-wrapper:after {
    content: " ";
    display: table;
    clear: both;
}

.vtimeline .timeline-wrapper .timeline-panel {
    border-radius: 2px;
    padding: 20px;
    position: relative;
    background: #ffffff;
    border-radius: 0px;
    box-shadow: 0 8px 16px 0 rgba(162, 169, 204, .24);
    width: 35%;
    margin-left: 15%;
}

.vtimeline .timeline-wrapper .timeline-panel:before {
    position: absolute;
    top: 0;
    width: 100%;
    height: 2px;
    content: "";
    left: 0;
    right: 0;
}

.vtimeline .timeline-wrapper .timeline-panel:after {
    position: absolute;
    top: 10px;
    right: -14px;
    display: inline-block;
    border-top: 14px solid transparent;
    border-left: 14px solid #cad4e4;
    border-right: 0 solid #cad4e4;
    border-bottom: 14px solid transparent;
    content: " ";
}

.vtimeline .timeline-wrapper .timeline-panel .timeline-title {
    font-size: 1rem;
    font-weight: 700;
    margin: 0 0 .625rem;
}

.vtimeline .timeline-wrapper .timeline-panel .timeline-body p+p {
    margin-top: 5px;
}

.vtimeline .timeline-wrapper .timeline-panel .timeline-body ul {
    margin-bottom: 0;
}

.vtimeline .timeline-wrapper .timeline-panel .timeline-footer span {
    font-size: .6875rem;
}

.vtimeline .timeline-wrapper .timeline-badge {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 16px;
    left: calc(50% - 7px);
    z-index: 0;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    border: 2px solid #ffffff;
}

.vtimeline .timeline-wrapper .timeline-badge i {
    color: #ffffff;
}

.vtimeline .timeline-wrapper.timeline-inverted {
    padding-right: 0;
    padding-left: 90px;
}

.vtimeline .timeline-wrapper.timeline-inverted .timeline-panel {
    margin-left: auto;
    margin-right: 15%;
}

.vtimeline .timeline-wrapper.timeline-inverted .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
}

.timeline-wrapper-primary .timeline-panel:before {
    background: #33689C;
}

.timeline-wrapper-primary .timeline-badge {
    background: #33689C;
}

.timeline-wrapper-secondary .timeline-panel:before {
    background: #eb6f33;
}

.timeline-wrapper-secondary .timeline-badge {
    background: #eb6f33;
}

.timeline-wrapper-success .timeline-panel:before {
    background: #03c895;
}

.timeline-wrapper-success .timeline-badge {
    background: #03c895;
}

.timeline-wrapper-info .timeline-panel:before {
    background: #01b8ff;
}

.timeline-wrapper-info .timeline-badge {
    background: #01b8ff;
}

.timeline-wrapper-warning .timeline-panel:before {
    background: #fcd539;
}

.timeline-wrapper-warning .timeline-badge {
    background: #fcd539;
}

.timeline-wrapper-danger .timeline-panel:before {
    background: #ff473d;
}

.timeline-wrapper-danger .timeline-badge {
    background: #ff473d;
}

.timeline-wrapper-light .timeline-panel:before {
    background: #f1f2f9;
}

.timeline-wrapper-light .timeline-badge {
    background: #f1f2f9;
}

.timeline-wrapper-dark .timeline-panel:before {
    background: #1c273c;
}

.timeline-wrapper-dark .timeline-badge {
    background: #1c273c;
}

.text-wrap {
    font-size: 14px;
    line-height: 1.66;
}

.example {
    padding: 1rem;
    border: 1px solid #e1e6f1;
    font-size: 0.9375rem;
}

.example+.highlight {
    border-top: none;
    margin-top: 0;
}

.text-wrap> :last-child {
    margin-bottom: 0;
}

.highlight {
    margin: 1rem 0 2rem;
    border: 1px solid #e1e6f1;
    border-radius: 0px;
    font-size: 0.9375rem;
    max-height: 20rem;
    background: #edeff7;
	overflow: auto;
}

.clip-widget {
    position: relative;
}

.clipboard-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 14px;
    background: #fff;
    border: 1px solid #e1e6f1;
    padding: 5px 10px;
    border-radius: 0px;
    cursor: pointer;
}

.icon-service {
    display: inline-flex;
    width: 3.5rem;
    height: 3.5rem;
    padding: 1.1rem;
    margin-bottom: 0.5rem;
}

.icon-service i {
    font-size: 1.2rem;
}

/*-- Bg-transparent---*/
.bg-success-transparent {
    background-color: rgba(56, 210, 80, 0.2);
}

.bg-secondary-transparent {
    background-color: rgba(236, 131, 27, 0.2);
}

.bg-primary-transparent {
    background-color: rgba(135, 96, 251, 0.2);
}

.bg-info-transparent {
    background-color: rgba(1, 184, 255, 0.2);
}

.bg-warning-transparent {
    background-color: rgba(253, 160, 8, 0.2);
}

.bg-danger-transparent {
    background-color: rgba(255, 71, 61, 0.2);
}

.bg-pink-transparent {
    background-color: rgba(241, 0, 117, 0.2);
}

.bg-purple-transparent {
    background-color: rgba(111, 66, 193, 0.2);
}

.bg-dark-transparent {
    background-color: rgba(28, 39, 60, 0.2);
}

.bg-white-transparent {
    background-color: rgba(255, 255, 255, 0.2);
}

.our-team .picture {
    height: 100px;
    width: 100px;
    margin: 0 auto;
    z-index: 0;
    position: relative;
    overflow: hidden;
}

.our-team:hover .picture::before {
    height: 100%;
}

.our-team .picture::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #33689C;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.our-team .picture img {
    width: 100%;
    height: auto;
    border-radius: 50%;
    transform: scale(1);
    transition: all 0.9s ease 0s;
}

.our-team:hover .picture img {
    box-shadow: 0 0 0 3px #e1e6f1;
    transform: scale(0.8);
}

.iti__hide {
    display: none;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
    background: none;
}

.user-lock img {
    width: 70px;
    height: 70px;
    margin: 0 auto;
}

.eamil-body {
    margin-top: 30px;
}

/*-- rating--*/
.rating-stars {
    width: 100%;
    text-align: center;
}

.rating-stars .rating-stars-container {
    font-size: 0px;
}

.rating-stars .rating-stars-container .rating-star {
    display: inline-block;
    font-size: 30px;
    cursor: pointer;
    padding: 4px 8px;
    color: #e1e6f1;
}

.rating-stars .rating-stars-container .rating-star.is--active .fa-heart,
.rating-stars .rating-stars-container .rating-star.is--hover .fa-heart {
    color: #fb0d00;
}

.rating-stars .rating-stars-container .rating-star.sm {
    display: inline-block;
    font-size: 14px;
    color: #eaedf1;
    cursor: pointer;
    padding: 5px;
}

.rating-stars .rating-stars-container .rating-star.is--active,
.rating-stars .rating-stars-container .rating-star.is--hover {
    color: #f1c40f;
}

.rating-stars .rating-stars-container .rating-star.is--no-hover {
    color: #f1f1f9;
}

.rating-stars .rating-stars-container .rating-star .fa-heart .is--no-hover {
    color: #f1f1f9;
}

/*-----Br Themes-----*/
.br-theme-bars-horizontal .br-widget a {
    background-color: #e1e6f1;
}

.br-theme-bars-horizontal .br-widget a.br-active,
.br-theme-bars-horizontal .br-widget a.br-selected {
    background-color: #33689C;
}

.br-theme-bars-horizontal .br-widget .br-current-rating {
    color: #33689C;
}

.br-theme-bars-pill .br-widget a {
    background-color: #e1e6f1;
}

.br-theme-bars-pill .br-widget a.br-active,
.br-theme-bars-pill .br-widget a.br-selected {
    background-color: #33689C;
    color: white;
}

.br-theme-bars-pill .br-widget a {
    color: #33689C;
}

.br-theme-bars-square .br-widget a.br-active,
.br-theme-bars-square .br-widget a.br-selected {
    border: 2px solid #e1e6f1;
    color: #33689C;
}

.br-theme-bars-square .br-widget a {
    border: 2px solid #e1e6f1;
    background-color: #fff;
    color: #334151;
}

.br-theme-bars-movie .br-widget a.br-active,
.br-theme-bars-movie .br-widget a.br-selected {
    background-color: #33689C;
}

.br-theme-bars-movie .br-widget a {
    background-color: #e1e6f1;
}

.br-theme-bars-movie .br-widget .br-current-rating {
    color: #33689C;
}

.br-theme-bars-1to10 .br-widget a {
    background-color: #e1e6f1;
}

.br-theme-bars-1to10 .br-widget a.br-active,
.br-theme-bars-1to10 .br-widget a.br-selected {
    background-color: #33689C;
}

.br-theme-bars-1to10 .br-widget .br-current-rating {
    color: #33689C;
}

.br-theme-fontawesome-stars .br-widget a.br-active:after {
    color: #33689C;
}

.br-theme-fontawesome-stars .br-widget a.br-selected:after {
    color: #33689C;
}

/*-----Br Themes-----*/
.rating-stars input {
    display: block;
    margin: 0 auto;
    text-align: center;
    padding: 0.375rem 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.6;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #eaedf1;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.task-list {
    list-style: none;
    position: relative;
    margin: 0;
    padding: 30px 0 0;
}

.task-list:after {
    content: "";
    position: absolute;
    background: #e1e6f1;
    height: 100%;
    width: 1px;
    top: 0;
    left: 30px;
    z-index: 1;
}

.task-list li {
    margin-bottom: 30px;
    padding-left: 55px;
    position: relative;
}

.task-list li:last-child {
    margin-bottom: 0;
}

.task-list li .task-icon {
    position: absolute;
    left: 22px;
    top: 13px;
    border-radius: 50%;
    padding: 2px;
    width: 17px;
    height: 17px;
    z-index: 2;
}

.task-list li h6 {
    margin-bottom: 5px;
}

.btn-group-sm>.btn,
.btn-sm {
    padding: .25rem .5rem;
    font-size: 0.75rem;
    line-height: 1.5;
    border-radius: 0rem;
    min-height: 0;
}

.product-img img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;

}

.product-img:hover img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

.item-card .product-info {
    padding: 0;
    margin: 0;
    opacity: 0;
    left: 0;
    right: 0;
    text-align: center;
    position: absolute;
    bottom: -30px;
    z-index: 1;
    transition: all .5s ease 0s;
}

.item-card:hover .product-info {
    opacity: 1;
    bottom: 30%;
}

.product_price li {
    display: inline-block;
    padding: 0 10px;
    font-weight: 500;
    font-size: 16px;
}

.product_price .old_price {
    text-decoration: line-through !important;
    color: #99a6b7;
}

.productdesc .list-unstyled li {
    border-bottom: 0 !important;
    margin-bottom: 18px;
}

.productdesc .list-unstyled li:last-child {
    margin-bottom: 0 !important;
}

.error-bg {
    background: url(../img/pngs/bg.png);
    background-position: center;
    background-size: cover;
}

.breadcrumb-3 ol {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
}

.breadcrumb-3 li {
    display: contents;
    text-align: center;
}

.breadcrumb-4 ol {
    position: relative;
    display: block;
    width: 100%;
    text-align: right;
}

.breadcrumb-4 li {
    display: contents;
    text-align: right;
}

/* Thumb Carousel */
.carousel-slider #carousel {
    margin: 0;
}

.carousel-slider #thumbcarousel {
    margin: 10px 0 0 0;
    padding: 0;
}

.carousel-slider #thumbcarousel .carousel-item {
    text-align: center;
}

.carousel-slider #thumbcarousel .carousel-item .thumb {
    width: 100%;
    margin: 0 2px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    max-width: 100px;
}

.carousel-slider #thumbcarousel .carousel-item .thumb:hover {
    border-color: #e8ebf3;
}

.carousel-slider .carousel-item img {
    width: 100%;
    height: auto;
}

.carousel-control:active,
.carousel-control:focus,
.carousel-control:hover {
    color: #333;
}

.carousel-caption,
.carousel-control .fa {
    font: normal normal normal 30px/26px FontAwesome;
}

#thumbcarousel .carousel-control-prev {
    left: 0;
    background: rgba(0, 0, 0, .8);
}

#thumbcarousel .carousel-control-next {
    right: 0;
    background: rgba(0, 0, 0, .8);
}

.owl-carousel .owl-item {
    position: relative;
    cursor: url(../plugins/owl-carousel/cursor.png), move;
    overflow: hidden;
}

.owl-nav {
    display: block;
}

.owl-carousel {
    position: relative;
}

.owl-nav .owl-prev {
    position: absolute;
    top: 50%;
    left: -19px;
    right: -1.5em;
    margin-top: -1.65em;
}

.owl-nav .owl-next {
    position: absolute;
    top: 50%;
    right: -19px;
    margin-top: -1.65em;
}

.owl-nav button {
    display: block;
    font-size: 1.3rem !important;
    line-height: 2em;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    text-align: center;
    background: rgba(255, 255, 255, .5) !important;
    border: 1px solid #e8ebf3 !important;
    z-index: 99;
    box-shadow: 0 4px 15px rgba(67, 67, 67, .15);
}

.owl-nav button:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #2098d1;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: .5s;
    transition-duration: .5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.owl-carousel:hover .owl-nav button {
    background: rgb(255, 255, 255) !important;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: .5s;
    transition-duration: .5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    animation: sonarEffect 1.3s ease-out 75ms;
}

.owl-nav>div i {
    margin: 0;
}

.owl-theme .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    bottom: .65em;
    left: 0;
    right: 0;
    z-index: 99;
}

.owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
}

.owl-theme .owl-dots .owl-dot span {
    width: 1em;
    height: 1em;
    margin: 5px 7px;
    background: rgba(0, 0, 0, .3);
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity .2s ease;
    border-radius: 30px;
}

.product-carousel .thumb {
    border: 1px solid #cad4e4;
    padding: 5px;
}

.btn-list {
    margin-bottom: -.5rem;
    font-size: 0;
    padding: 0;
}

.btn-list>.btn:not(:last-child),
.btn-list>.dropdown:not(:last-child) {
    margin-right: .5rem;
}

.btn-list>.btn,
.btn-list>.dropdown {
    margin-bottom: .5rem;
}

.echart-height {
    height: 250px;
}

.main-profile-menu .border-top {
    border-top: 1px solid #e1e6f1 !important;
}

/* Input Height */
.input-lg {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
}

.input-sm {
    height: 28px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
}

select.form-control.select-lg {
    height: 46px !important;
    padding: 10px 16px !important;
    font-size: 17px !important;
    line-height: 1.3333333 !important;
}

select.form-control.select-sm {
    height: 28px !important;
    padding: 0px 10px 1px 10px !important;
    line-height: 1 !important;
}

/*----Custom Switch-----*/
.custom-switch {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    margin: 0;
    padding-left: 0;
}

.custom-switch-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom-switches-stacked {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}

.custom-switches-stacked .custom-switch {
    margin-bottom: .5rem;
}

.custom-switch-indicator {
    display: inline-block;
    height: 1.25rem;
    width: 2.25rem;
    background: #e5e9f3;
    border-radius: 50px;
    position: relative;
    vertical-align: bottom;
    border: 1px solid #e1e6f1;
    transition: .3s border-color, .3s background-color;
}

.custom-switch-indicator:before {
    content: '';
    position: absolute;
    height: calc(1.25rem - 4px);
    width: calc(1.25rem - 4px);
    top: 1px;
    left: 1px;
    background: #fff;
    border-radius: 50%;
    transition: .3s left;
    box-shadow: none;
}

.custom-switch-input:checked~.custom-switch-indicator {
    background: #33689C;
}

.custom-switch-input:checked~.custom-switch-indicator:before {
    left: calc(1rem + 1px);
}

.custom-switch-input:focus~.custom-switch-indicator {
    box-shadow: none;
    border-color: #33689C;
}

.custom-switch-description {
    margin-left: .5rem;
    color: #99a6b7;
    transition: .3s color;
}

.custom-switch-input:checked~.custom-switch-description {
    color: #334151;
}

/*----Select Group-----*/
.selectgroup {
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.selectgroup-item {
    -ms-flex-positive: 1;
    flex-grow: 1;
    position: relative;
}

.selectgroup-item+.selectgroup-item {
    margin-left: -1px;
}

.selectgroup-item:not(:first-child) .selectgroup-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.selectgroup-item:not(:last-child) .selectgroup-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.selectgroup-input {
    opacity: 0;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
}

.selectgroup-button {
    display: block;
    border: 1px solid #e1e6f1;
    text-align: center;
    padding: 0.375rem 1rem;
    position: relative;
    cursor: pointer;
    border-radius: 3px;
    color: #686868;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.selectgroup-button-icon {
    padding-left: .5rem;
    padding-right: .5rem;
    font-size: 1rem;
}

.selectgroup-input:checked+.selectgroup-button {
    border-color: #33689C;
    z-index: 1;
    color: #33689C;
    background: #e5e9f3;
}

.selectgroup-input:focus+.selectgroup-button {
    border-color: #33689C;
    z-index: 2;
    color: #33689C;
    box-shadow: none;
}

.selectgroup-pills {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: start;
    align-items: flex-start;
}

.selectgroup-pills .selectgroup-item {
    margin-right: .5rem;
    -ms-flex-positive: 0;
    flex-grow: 0;
}

.colorinput {
    margin-right: 5px;
    position: relative;
    cursor: pointer;
}

.colorinput-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.colorinput-color {
    display: inline-block;
    width: 1.75rem;
    height: 1.75rem;
    border: 1px solid transparent;
    color: #fff;
    box-shadow: none;
}

.colorinput-color:before {
    content: '';
    opacity: 0;
    position: absolute;
    top: .25rem;
    left: .25rem;
    height: 1.25rem;
    width: 1.25rem;
    transition: .3s opacity;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center center/50% 50%;
}

.colorinput-input:checked~.colorinput-color:before {
    opacity: 1;
}

.acc-step-number {
    padding: 5px 10px;
    font-size: 14px;
}

/* Tags */
.tags {
    margin-bottom: -.5rem;
    font-size: 0;
}

.tags>.tag:not(:last-child) {
    margin-right: .5rem;
}

.tag-addon {
    display: inline-block;
    padding: 0 .5rem;
    color: inherit;
    text-decoration: none;
    margin: 0 -.5rem 0 .5rem;
    text-align: center;
    min-width: 1.5rem;
    background: #d7dce6
}

.tag-addon i {
    vertical-align: middle;
    margin: 0 -.25rem;
}

.tag {
    font-size: 0.75rem;
    color: #fff;
    border-radius: 0px;
    padding: 0 .5rem;
    line-height: 2em;
    display: -ms-inline-flexbox;
    display: inline-flex;
    cursor: default;
    font-weight: 400;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.tag-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 50px;
}

.tag-default {
    background-color: #e1e6f1;
    color: #1c273c;
}

.tag-dark {
    background-color: #334151;
}

.tag-primary {
    background-color: #33689C;
}

.tag-secondary {
    background-color: #eb6f33;
}

.tag-info {
    background-color: #01b8ff;
}

.tag-warning {
    background-color: #f3b90b;
    color: #1c273c;
}

.tag-danger {
    background-color: #ff473d;
}

.tag-success {
    background-color: #03c895;
}

/* Mapel map */
.mapael .map {
    margin-bottom: 10px;
    position: relative;
}

.mapael .zoomButton {
    background-color: #292929;
    border: 1px solid #292929;
    color: #fff;
    width: 15px;
    height: 15px;
    line-height: 12px;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    position: absolute;
    top: 0;
    font-weight: bold;
    left: 10px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.mapael .zoomReset {
    top: 10px;
}

.mapael .zoomIn {
    top: 30px;
}

.mapael .zoomOut {
    top: 50px;
}

.mapael .mapTooltip {
    position: absolute;
    background-color: #474c4b;
    moz-opacity: 0.70;
    opacity: 0.70;
    filter: alpha(opacity=70);
    border-radius: 10px;
    padding: 10px;
    z-index: 1000;
    max-width: 200px;
    display: none;
    color: #fff;
}

/*
 * Loaders
 * ----------------------
 */

/*--circleloader--*/
.lds-circle {
    display: inline-block;
    transform: translateZ(1px);
}

.lds-circle>div {
    display: inline-block;
    width: 51px;
    height: 51px;
    margin: 6px;
    border-radius: 50%;
    animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    background: #33689C;
}

@keyframes lds-circle {

    0%,
    100% {
        animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }

    0% {
        transform: rotateY(0deg);
    }

    50% {
        transform: rotateY(1800deg);
        animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }

    100% {
        transform: rotateY(3600deg);
    }
}

/*---ring loaders --*/
.lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    animation: lds-dual-ring 1.2s linear infinite;
    border: 5px solid #33689C;
    border-color: #33689C transparent #33689C transparent;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*-- lines loader --*/
.lds-facebook {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}

.lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 6px;
    width: 13px;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    background: #33689C;
}

.lds-facebook div:nth-child(1) {
    left: 6px;
    animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
    left: 26px;
    animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
    left: 45px;
    animation-delay: 0;
}

@keyframes lds-facebook {
    0% {
        top: 6px;
        height: 51px;
    }

    50%,
    100% {
        top: 19px;
        height: 26px;
    }
}

/*--heart loader --*/
.lds-heart {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
    transform: rotate(45deg);
    transform-origin: 32px 32px;
}

.lds-heart div {
    top: 23px;
    left: 19px;
    position: absolute;
    width: 26px;
    height: 26px;
    animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
    background: #33689C;
}

.lds-heart div:after,
.lds-heart div:before {
    content: " ";
    position: absolute;
    display: block;
    width: 26px;
    height: 26px;
    background: #33689C;
}

.lds-heart div:before {
    left: -17px;
    border-radius: 50% 0 0 50%;
}

.lds-heart div:after {
    top: -17px;
    border-radius: 50% 50% 0 0;
}

@keyframes lds-heart {
    0% {
        transform: scale(0.95);
    }

    5% {
        transform: scale(1.1);
    }

    39% {
        transform: scale(0.85);
    }

    45% {
        transform: scale(1);
    }

    60% {
        transform: scale(0.95);
    }

    100% {
        transform: scale(0.9);
    }
}

/**-ripple --*/
.lds-ripple {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}

.lds-ripple div {
    position: absolute;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    border: 4px solid #33689C;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top: 28px;
        left: 28px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: -1px;
        left: -1px;
        width: 58px;
        height: 58px;
        opacity: 0;
    }
}

/*-- Spinner loader --*/
.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}

.lds-spinner div {
    transform-origin: 32px 32px;
    animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 29px;
    width: 5px;
    height: 14px;
    border-radius: 20%;
    background: #33689C;
}

.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

@keyframes lds-spinner {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.dt-button-collection .dropdown-item.active,
.dt-button-collection .dropdown-item:active {
    color: #334151;
    text-decoration: none;
    background-color: #fff;
}

.img-thumbnail .caption {
    padding: 15px;
}

.sidebar-icon {
    position: absolute;
    right: 8px;
    top: 3px;
}

/* Animations */
.pulse {
    display: block;
    position: absolute;
    top: 6px;
    right: 17px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #ff473d;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(255, 71, 61, 0.9);
    animation: pulse 2s infinite;
}

.pulse:hover {
    animation: none;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 71, 61, 0.9);
    }

    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(255, 71, 61, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 71, 61, 0);
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(255, 71, 61, 0.9);
        box-shadow: 0 0 0 0 rgba(255, 71, 61, 0.7);
    }

    70% {
        -moz-box-shadow: 0 0 0 10px rgba(255, 71, 61, 0);
        box-shadow: 0 0 0 10px rgba(255, 71, 61, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(255, 71, 61, 0);
        box-shadow: 0 0 0 0 rgba(255, 71, 61, 0);
    }
}

.ripple {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    cursor: pointer;
}

.ripple:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform .5s, opacity 1s
}

.ripple:active:after {
    transform: scale(0, 0);
    opacity: .2;
    transition: 0s;
}

@keyframes fadein {
    from {
        opacity: 0;
        transform: translate3d(0, 20px, 0)
    }

    to {
        opacity: 1;
        transform: none;
        transform: translate3d(0, 0, 0)
    }
}

.fadein,
.main-header-notification.show .dropdown-menu,
.main-profile-menu.show .dropdown-menu,
.header-search.show .dropdown-menu {
    animation-name: fadein;
    -webkit-animation-duration: .2s;
    -moz-animation-duration: .2s;
    -ms-animation-duration: .2s;
    -o-animation-duration: .2s;
    animation-duration: .2s;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both
}

.main-header-notification.show .dropdown-menu::before,
.main-profile-menu.show .dropdown-menu::before,
.header-search.show .dropdown-menu::before {
    content: '';
    width: 13px;
    height: 13px;
    background: #fff;
    position: absolute;
    top: -7px;
    right: 28px;
    transform: rotate(45deg);
    border-top: 1px solid #e1e6f1;
    border-left: 1px solid #e1e6f1;
}

.header-search.show .dropdown-menu {
    display: block;
    margin: 0;
}

.header-search .dropdown-menu {
    width: 300px;
    position: absolute;
    top: 50px;
    left: auto;
    right: -10px;
    bottom: auto;
    padding: 0;
    border-color: #e1e6f1;
    border-width: 1px;
    box-shadow: 0 8px 16px 0 rgba(162, 169, 204, .24)
}

.main-form-search {
    flex: 1;
    width: 100%;
    position: relative;
}

.main-form-search .form-control {
    border-color: #e1e6f1;
    border-width: 1px;
    background-color: #fff;
    transition: none;
    height: 40px;
}

.main-form-search .form-control:focus {
    background-color: #fff;
    border-color: #b4bdce;
    box-shadow: none !important;
}

.main-form-search .form-control:focus+.btn,
.main-form-search .sp-container .form-control:focus+button,
.sp-container .main-form-search .form-control:focus+button {
    color: #99a6b7;
}

.main-form-search .btn,
.main-form-search .sp-container button,
.sp-container .main-form-search button {
    position: absolute;
    top: 8px;
    right: 5px;
    background-color: transparent;
    height: 40px;
    color: #b4bdce;
    transition: none;
    font-size: 16px;
    padding-right: 13px;
}

.main-form-search .btn:hover,
.main-form-search .sp-container button:hover,
.sp-container .main-form-search button:hover,
.main-form-search .btn:focus,
.main-form-search .sp-container button:focus,
.sp-container .main-form-search button:focus {
    color: #334151;
}

.theme-logo {
    display: none !important
}

/*------ Charts Heights ------*/
.chart-height1 {
    min-height: 15rem;
    overflow: hidden;
}

.chart-height2 {
    height: 16rem;
    overflow: hidden;
}

.chart-height3 {
    height: 17rem;
    overflow: hidden;
}

.chart-height4 {
    height: 18rem;
    overflow: hidden;
}

.chart-height5 {
    height: 20rem;
    overflow: hidden;
}

.chart-height6 {
    height: 22rem;
    overflow: hidden;
}

.img-flag {
    width: 25px;
    height: 12px;
    margin-top: -4px;
}

/*Advanced Filter */
.advanced-search {
    background: #fff;
    padding: 1.25rem;
    box-shadow: 0 8px 16px 0 rgba(162, 169, 204, .24);
    margin-bottom: 20px;
}

.theme-logos {
    display: none;
}

.dot-label {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 100%;
    margin-right: 5px;
}

.contact-info .contact-icon:hover {
    color: #000;
}

.scroll-widget {
    width: 100%;
    height: 385px;
    overflow: hidden;
}

.activity-block .task-list li {
    padding-left: 25px;
    margin-bottom: 20px;
}

.activity-block .task-list {
    padding-top: 0;
}

.activity-block h6 {
    margin-bottom: 0px;
}

.activity-block .task-list:after {
    left: 5px;
    z-index: 0;
}

.activity-block .task-list li .task-icon {
    top: 0;
    left: 0px;
    width: 12px;
    height: 12px;
}

.card-blog-overlay1 {
    background: url('../img/media/3.jpg');
    background-size: cover;
}

.card-blog-overlay2 {
    background: url('../img/media/1.jpg');
    background-size: cover;
}

.z-index2 {
    z-index: 2;
}

.card-blog-overlay {
    background: url('../img/media/2.jpg');
    background-size: cover;
    position: relative;
}

.card-blog-overlay:before,
.card-blog-overlay1:before,
.card-blog-overlay2:before {
    content: '';
    display: block;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
}

.card-blog-overlay .custom-card-header,
.card-blog-overlay1 .custom-card-header,
.card-blog-overlay2 .custom-card-header {
    background: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2)
}

.card-blog-overlay .card-footer,
.card-blog-overlay1 .card-footer,
.card-blog-overlay2 .card-footer {
    background: none;
    border-top: 1px solid rgba(255, 255, 255, 0.2)
}

div.dtr-modal div.dtr-modal-content {
    box-shadow: none;
}

.table.dtr-details {
    margin-bottom: 0;
}

.main-notification-text .badge {
    line-height: 1.5;
}

/*loader*/
#global-loader {
    position: fixed;
    z-index: 50000;
    background: #fff;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    display: block;
}

.loader-img {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 43%;
    left: 0;
    margin: 0 auto;
    text-align: center;
}

.user-info {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}

.side-header .main-profile-menu .dropdown-menu {
    top: 53px;
}

.side-header .header-search .dropdown-menu {
    top: 50px;
}

#example-input .form-control::placeholder,
#example-input .form-control {
    color: #334151 !important;
}

/*  ----- center logo ----- */
.setting-header {
    font-weight: 400;
    font-size: 15px;
}

.main-header-message {
    position: relative;
}

.fadein,
.main-header-message.show .dropdown-menu {
    animation-name: fadein;
    -webkit-animation-duration: .2s;
    -moz-animation-duration: .2s;
    -ms-animation-duration: .2s;
    -o-animation-duration: .2s;
    animation-duration: .2s;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
}

.main-header-message.show .dropdown-menu {
    display: block;
}

.main-header-message .dropdown-menu {
    width: 300px;
    position: absolute;
    top: 48px;
    right: auto;
    left: -10px;
    bottom: auto;
    padding: 0;
    border-color: #e1e6f1;
    border-width: 1px;
    box-shadow: 0 8px 16px 0 rgba(162, 169, 204, .24);
}

.main-header-message.show .dropdown-menu::before {
    content: '';
    width: 13px;
    height: 13px;
    background: #fff;
    position: absolute;
    top: -7px;
    left: 12px;
    transform: rotate(45deg);
    border-top: 1px solid #e1e6f1;
    border-left: 1px solid #e1e6f1;
}

.main-message-list .media {
    padding: 10px 10px 0px 15px;
}

.main-header-message .dropdown-footer {
    text-align: center;
    padding: 10px;
    font-size: 13px;
    border-top: 1px solid rgba(123, 65, 216, 0.1);
}

.main-header-flags {
    position: relative;
}

.fadein,
.main-header-flags.show .dropdown-menu {
    animation-name: fadein;
    -webkit-animation-duration: .2s;
    -moz-animation-duration: .2s;
    -ms-animation-duration: .2s;
    -o-animation-duration: .2s;
    animation-duration: .2s;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
}

.main-header-flags.show .dropdown-menu {
    display: block;
}

.main-header-flags .dropdown-menu {
    width: 200px;
    position: absolute;
    top: 48px;
    right: auto;
    left: -10px;
    bottom: auto;
    padding: 0;
    border-color: #e1e6f1;
    border-width: 1px;
    box-shadow: 0 8px 16px 0 rgba(162, 169, 204, .24);
}

.main-header-flags.show .dropdown-menu::before {
    content: '';
    width: 13px;
    height: 13px;
    background: #fff;
    position: absolute;
    top: -7px;
    left: 28px;
    transform: rotate(45deg);
    border-top: 1px solid #e1e6f1;
    border-left: 1px solid #e1e6f1;
}

.main-flags-list .media {
    padding: 10px 10px 0px 15px;
}

.main-header-flags .dropdown-footer {
    text-align: center;
    padding: 10px;
    font-size: 13px;
    border-top: 1px solid rgba(123, 65, 216, 0.1);
}

.header-flag {
    width: 1.7rem;
}

.main-flags-list img {
    width: 1.7rem;
}

.hor-header.hor-top-header .nav-link-badge {
    position: absolute;
    top: 0px;
    left: 11px;
    display: block !important;
    padding: 3px 5px !important;
    border-radius: 50px;
    font-size: 10px;
}

.hor-header.hor-top-header .main-message-list p {
    font-size: 12px;
}

@media (max-width: 991px) {
    .main-header-message {
        display: none;
    }

    .main-header-flags {
        display: none;
    }

    .hor-header.hor-top-header .main-logo {
        left: 0px;
        margin-right: auto;
    }
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $success;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  color: $white;
  background-color: rgba(59, 176, 1, 0.9);
  border-radius: 3px;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $danger;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  color: $white;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 3px;
}

input {
  &[type="submit"].btn-block, &[type="reset"].btn-block, &[type="button"].btn-block {
    width: 100%;
  }
}

.fade {
  transition: opacity 0.15s linear;

  &:not(.show) {
    opacity: 0;
  }
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

.tab-content > {
  .tab-pane {
    display: none;
  }

  .active {
    display: block;
  }
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color:$border;
  background-color:$border;
  border-radius: 0.3rem;
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: $muted;
  text-shadow: 0 1px 0 $white;

  &:hover {
    color: $color;
    text-decoration: none;
  }
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
  background-color: rgba(255, 255, 255, 0.95);
  border-color:$border;
  box-shadow: 0 2px 3px rgba(28, 39, 60, 0.06);
  border-radius: 0px;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  &.showing {
    opacity: 1;
  }

  &.show {
    display: block;
    opacity: 1;
  }

  &.hide {
    display: none;
  }
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom-color: rgba(205, 212, 224, 0.4);
}

.toast-body {
  padding: 0.75rem;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

*::-ms-backdrop, .d-sm-flex.media.media-list {
  display: initial;
}

*::-ms-backdrop, .t-img {
  height: 197px !important;
}

*::-ms-backdrop, .draggable-img, *::-ms-backdrop, .blog-img {
  height: 258px !important;
}

*::-ms-backdrop, .main-error-wrapper {
  align-items: inherit !important;
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &::before {
    display: block;
    content: "";
  }

  .embed-responsive-item, iframe, embed, object, video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable {
  &:active, &:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.toast-header .close {
  font-weight: 300;
  color: $muted;
}

.step-equal-width > .steps > ul {
  display: flex;
  display: flex;

  > li {
    flex: 1;
    width: auto;
    float: none;
    flex: 1;
    width: auto;
    float: none;
  }
}

.morris-hover.morris-default-style {
  border-radius: 0;
}

.ps {
  overflow: hidden;

  > .ps__rail-y {
    width: 5px;
    background-color: rgba(28, 39, 60, 0.04);
    z-index: 10;
    position: absolute;
    left: auto !important;
    right: 0;
    opacity: 0;
    transition: opacity 0.2s;

    > .ps__thumb-y {
      position: absolute;
      border-radius: 0;
      width: 3px;
      left: 1px;
      background-color: $muted;
    }
  }

  &.ps--active-y {
    &:hover > .ps__rail-y, &:focus > .ps__rail-y {
      opacity: 1;
      right: -1px !important;
    }
  }
}

.main-donut-chart {
  position: relative;
  border-radius: 50%;
  overflow: hidden;

  .slice {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .chart-center {
    position: absolute;
    border-radius: 50%;

    span {
      display: block;
      text-align: center;
    }
  }
}

.main-toggle {
  width: 60px;
  height: 25px;
  background-color: #b4bdce;
  padding: 2px;
  position: relative;
  overflow: hidden;

  span {
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    display: block;
    width: 20px;
    background-color: $white;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &::before, &::after {
      position: absolute;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: .5px;
      text-transform: uppercase;
      color: $white;
      top: 2px;
      line-height: 1.38;
    }

    &::before {
      content: 'on';
      left: -25px;
    }

    &::after {
      content: 'off';
      right: -29px;
    }
  }

  &.on {
    background-color: $primary;

    span {
      left: 37px;
    }
  }
}

.main-toggle-secondary.on {
  background-color: $secondary;
}

.main-toggle-success.on {
  background-color: $success;
}

.main-toggle-dark.on {
  background-color: $color;
}

.main-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  + .main-list-item {
    border-top: 1px solid$border;
  }

  &:last-child {
    padding-bottom: 0;
  }

  h6 {
    margin-bottom: 2px;
    font-weight: 600;
  }

  > div {
    &:first-child h6 {
      color: $color;
    }

    span {
      color: $muted;
      font-size: 12px;
    }

    &:last-child {
      text-align: right;

      h6 {
        line-height: 1;
      }
    }
  }
}

.main-tab-pane {
  display: none;

  &.active {
    display: block;
  }
}

.main-body, .main-dashboard {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.main-body::before {
  content: 'main';
  position: fixed;
  top: 45%;
  left: 70%;
  transform: translate3d(-50%, -58%, 0);
  font-size: 1300px;
  font-weight: 600;
  letter-spacing: -10px;
  line-height: .5;
  opacity: .02;
  z-index: -1;
  display: none;
}

/*  ----- 6.8 Sidebar   ----- */


.main-body-sidebar {
  display: block;

  .main-content {
    flex: 1;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  .main-content-header {
    height: auto;
    padding: 20px;
    margin-bottom: 0;
  }

  .main-content-body {
    padding: 0 20px 20px;
  }

  .main-footer {
    margin-top: auto;
  }
}

.main-toggle-group-demo {
  display: flex;

  .main-toggle + .main-toggle {
    margin-left: 10px;
  }
}

.morris-wrapper-demo {
  height: 200px;
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
}

.morris-donut-wrapper-demo {
  height: 200px;
}

.chartjs-wrapper-demo {
  height: 250px;
}

.chartist-wrapper-demo {
  height: 200px;
}

.main-syntax-wrapper {
  position: relative;

  .badge {
    padding: 4px 5px;
    position: absolute;
    top: 5px;
    right: 5px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 9px;
    letter-spacing: normal;
    border-radius: 1px;
  }

  pre {
    border-color:$border;
    background-color: $white;
    padding: 15px;
    font-size: 13px;
  }

  code[class*="language-"], pre[class*="language-"] {
    font-family: 'Roboto Mono', monospace;
  }
}

.demo-static-toast .toast {
  opacity: 1;
}

.main-msg-wrapper {
  position: relative;

  &:before, &:after {
    border-color: transparent #f1f2f9 transparent transparent;
    border-image: none;
    border-style: solid;
    border-width: medium;
    content: " ";
    height: 0;
    pointer-events: none;
    position: absolute;
    right: 100%;
    top: 50%;
    width: 0;
    border-width: 8px;
    margin-top: -8px;
  }

  &:before {
    margin-top: -6px;
  }

  padding: 10px 15px;
  background-color: #f1f2f9;
  font-size: 13px;
  margin-bottom: 5px;
  display: inline-block;
}

.main-msg-send {
  font-size: 20px;
  color: $primary;
}

/*  ----- 7.5 Invoice   ----- */

.billed-from {
  margin-top: 25px;
  margin-bottom: 25px;

  h6 {
    color: $color;
    font-size: 14px;
    font-weight: 700;
  }

  p {
    font-size: 13px;
    margin-bottom: 0;
  }
}

.billed-to {
  h6 {
    color: $color;
    font-size: 14px;
    font-weight: 700;
  }

  p {
    font-size: 13px;
    margin-bottom: 0;
  }
}

.main-traffic-detail-item {
  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    margin-bottom: 7px;

    > span {
      &:first-child {
        color: $muted;
      }

      &:last-child {
        font-size: 11px;
        font-weight: 700;
        color: $color;

        span {
          color: $muted;
          font-weight: 400;
        }
      }
    }
  }

  + .main-traffic-detail-item {
    margin-top: 18px;
  }
}

/*  ----- 7.11 Error   ----- */

.main-error-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  text-align: center;

  h1 {
    line-height: 1;
    font-size: 8rem;
    font-weight: 700;
    color: $color;
  }

  h2 {
    font-weight: 500;
    color: #3b4863;
    letter-spacing: -.5px;
    margin-bottom: 15px;
  }

  h6 {
    margin-bottom: 40px;
    font-size: 14px;
  }

  .btn, .sp-container button {
    border-width: 2px;
    font-weight: 400;
  }
}

.sp-container .main-error-wrapper button {
  border-width: 2px;
  font-weight: 400;
}

.main-star-group {
  display: flex;
  align-items: center;

  span:last-child {
    display: block;
    font-weight: 500;
    font-size: 11px;
    margin-left: 5px;
    color: $muted;
  }
}

.main-star-item {
  color: $primary;
  font-size: 14px;
  position: relative;

  + .main-star-item {
    margin-left: 3px;
  }
}

.overflow-y-auto {
  overflow-y: auto;
}

.img-fit-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-img-user {
    display: block;
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 100%;
    }
  
  .error-bg {
    background: url(../img/pngs/bg.png);
    background-position: center;
    background-size: cover;
    }
}

.main-signin-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
  }

/*---- IE css ---*/
*::-ms-backdrop, .b-img {
  height: 197px !important;
}
@media print {
    * {
      text-shadow: none !important;
      box-shadow: none !important;
  
      &::before, &::after {
        text-shadow: none !important;
        box-shadow: none !important;
      }
    }
  
    a:not(.btn) {
      text-decoration: underline;
    }
  
    abbr[title]::after {
      content: " (" attr(title) ")";
    }
  
    pre {
      white-space: pre-wrap !important;
      border: 1px solid $muted;
      page-break-inside: avoid;
    }
  
    blockquote {
      border: 1px solid $muted;
      page-break-inside: avoid;
    }
  
    thead {
      display: table-header-group;
    }
  
    tr, img {
      page-break-inside: avoid;
    }
  
    p, h2, h3 {
      orphans: 3;
      widows: 3;
    }
  
    h2, h3 {
      page-break-after: avoid;
    }
  
    @page {
      size: a3;
    }
  
    body, .container {
      min-width: 992px !important;
    }
}
  @media (prefers-reduced-motion: reduce) {
    .fade, .collapsing, .ps > .ps__rail-y {
      transition: none;
    }
  
    .main-accordion {
      .accordion-item, .accordion-title {
        transition: none;
      }
    }
  
    .main-toggle span {
      transition: none;
    }
}
@media (max-width: 330px) {
    .chartjs-wrapper-demo {
      width: 290px;
    }
}
@media (max-width: 380px) {
    .main-header-notification {
        display: none;
    }

    .carousel-slider #thumbcarousel .carousel-item .thumb {
        margin: 5px !important;
    }

    .main-nav-line-chat .nav-link {
        font-size: 12px;
    }

    .main-mail-header>div:last-child {
        display: none;
    }
}
@media (max-width: 575.98px) {
    .main-logo .header-brand-img.desktop-logo {
        display: none;
    }

    .main-logo .header-brand-img.icon-logo {
        display: block;
    }

    .flot-chart1 {
        height: 200px !important;
    }

    .main-contact-info-header .media-body .nav-link {
        font-size: 12px;
    }

    .modal.pos-static .modal-footer {
        display: block;
    }

    .modal.pos-static .modal-footer .btn {
        width: 100%;
    }

    .modal.pos-static .modal-footer> :not(:first-child) {
        margin-left: 0;
        margin-top: 10px;
    }
}
@media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem;
    }
  
    .morris-wrapper-demo {
      height: 300px;
    }
  
    .morris-donut-wrapper-demo {
      height: 250px;
    }
    
    .demo-avatar-group {
      .main-img-user + {
        .main-img-user, .main-avatar {
          margin-left: 10px;
        }
      }
      .main-avatar + {
        .main-img-user, .main-avatar {
          margin-left: 10px;
        }
      }
    }
  
    .main-column-signup-left {
      padding: 40px;
    }
  
    .main-column-signup {
      padding: 40px;
      width: 500px;
    }
      
}
 @media(max-width:767px) {
    .main-content-body-contacts {
        border-top: 1px solid #e1e6f1;
    }

    .card-option {
        display: inline-block !important;
    }

    .card-option-title {
        margin-bottom: 10px;
    }

    .header-search.dropdown {
        position: initial !important;
    }

    .header-search .dropdown-menu {
        position: absolute !important;
        left: -1px !important;
        right: -1px !important;
        width: 100% !important;
        top: 100% !important;
    }

    .header-search.show .dropdown-menu::before {
        display: none;
    }

    .timeline .btn {
        font-size: 12px;
    }

    .vtimeline:before {
        left: 6px !important;
    }

    .vtimeline .timeline-wrapper .timeline-badge {
        left: 0 !important;
    }

    .vtimeline .timeline-wrapper {
        padding-right: 0 !important;
    }

    .vtimeline .timeline-wrapper .timeline-panel {
        width: auto !important;
        margin-left: 10% !important;
    }

    .vtimeline .timeline-wrapper .timeline-panel:after {
        border-right: 14px solid #cad4e4 !important;
        border-left: 0 solid #cad4e4 !important;
        left: -14px !important;
        right: auto !important;
    }

    .vtimeline .timeline-wrapper.timeline-inverted {
        padding-left: 0 !important
    }

    .vtimeline .timeline-wrapper.timeline-inverted .timeline-panel {
        margin-right: 0 !important;
        width: auto !important
    }
}
@media (min-width: 768px) {
    .chartist-wrapper-demo {
      height: 300px;
    }
  
    .billed-from {
      margin-top: 0;
    }
 }
 @media (min-width: 992px) {
    .main-body-sidebar {
      display: flex;
      flex-direction: row;
  
      .main-header .container-fluid {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  
    .chartjs-wrapper-demo {
      height: 300px;
    }
  
    .main-signup-wrapper {
      justify-content: flex-end;
    }
  
    .main-column-signup-left {
      display: flex;
    }
  
    .main-column-signup {
      border-left: 1px solid$border;
    }
}
@media(max-width:992px) {
    .jumps-prevent {
        padding-top: 0 !important;
    }

    .main-navbar.sticky.sticky-pin {
        margin-bottom: 0 !important;
    }

    .page-header {
        display: block;
    }

    .page-header .breadcrumb {
        margin-bottom: 1rem;
    }

    #mailContent .main-mail-list {
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    #mailContent .main-mail-item {
        -webkit-overflow-scrolling: touch;
    }

    .main-chat-footer {
        position: inherit;
    }

    .main-header-notification .dropdown-menu {
        top: 46px;
    }

    .main-header-notification>a::after {
        top: 40px;
    }

    .main-profile-menu .dropdown-menu {
        top: 51px;
    }

    .main-profile-menu>.main-img-user::before {
        bottom: -20px;
    }

    .main-navbar .nav {
        padding: 0;
        margin-bottom: 0;
    }

    .main-navbar .nav-item {
        padding: 10px;
        border-bottom: 1px solid #e1e6f1;
        transition: all .25s;
        transition-duration: 0.25s;
    }

    .main-navbar .nav-link {
        height: auto;
    }

    .main-navbar .nav-sub {
        border-top: 0;
    }

    .main-navbar .nav-item.show>.nav-sub {
        padding: 5px;
    }

    .main-navbar .nav-item .nav-sub li:first-child {
        margin-top: 10px;
    }

    .main-navbar .nav-item .nav-sub li.nav-sub-item:first-child {
        margin-top: 0px;
    }

    .main-navbar .nav-sub-mega .nav li:not(.nav-sub-item):first-of-type {
        margin-top: 10px !important;
    }

    .main-navbar .nav-sub-mega .nav li:not(.nav-sub-item) {
        margin-left: 0;
    }

    .main-navbar .nav-sub-item {
        margin-left: 15px;
    }

    .main-header {
        position: fixed;
        box-shadow: none;
        border-bottom: 1px solid #e1e6f1;
        border-top: 0;
    }

    .header-settings .nav-link.icon {
        padding-right: 0;
    }

    .main-logo .header-brand-img {
        margin-left: 10px;
    }

    .side-header {
        position: fixed !important;
	    position: visibility;
    }

    .main-sidebar-hide .side-header {
        left: 0px;
        right: 0px;
    }

    .main-sidebar-hide .sidemenu-logo {
        padding: 5px;
    }

    .main-content.side-content .page-header {
        padding-top: 63px !important;
    }

    .main-content .page-header {
        padding-top: 63px !important;
    }

    .main-content .page-header .btn.btn-list {
        padding-left: 0;
        text-align: left;
    }

    #example-input.dataTable>tbody>tr.child ul.dtr-details>li {
        display: flex !important;
    }

}
@media (min-width: 992px) {
    .sticky {
        width: 100%;
    }
	.main-header.side-header{
		position: sticky;
        top: 0;
        width: 100%;
	}
    .sticky-pin {
        position: fixed;
        top: 0;
        width: 100%;
    }

    .main-navbar.sticky.sticky-pin {
        border-bottom: 1px solid #e1e6f1;
    }

    .main-content {
        padding-top: 58px;
    }

    .main-content.side-content {
        padding-top: 63px;
    }

    .main-content-app,
    .main-content-calendar,
    .main-content-contacts {
        display: flex;
    }

    .main-sidebar-hide .main-content.side-content {
        margin-left: 0;
    }

    .main-error-wrapper h1 {
        font-size: 12rem;
    }
}
@media(max-width:1024px) and (min-width:768px) {
    .main-header-right {
        margin-right: 15px;
    }
}
@media (min-width: 1200px) {
    *::-ms-backdrop, .main-navbar .nav-sub-mega .nav {
      min-width: 0 !important;
    }
}
.main-icon-group {
    text-align: center;
    padding: 20px;
    color: #3b4863;
}
.main-icon-list {
    padding: 10px;
    border: 1px solid #e1e6f1
}
*::-ms-backdrop, html.fullscreenie{
    width:100%;
}
*::-ms-backdrop, html.fullscreenie .app-content{
    overflow:scroll;
    overflow-x:hidden;
    height:100vh;
}
*::-ms-backdrop, html.fullscreenie .main-content {
    overflow:scroll;
    overflow-x:hidden;
    height:100vh;
}
*::-ms-backdrop, .btn, .sp-container button{
    padding: 0.375rem 0.75rem;
}
*::-ms-backdrop, .breadcrumb-3 li{
   display :-ms-inline-flexbox;
}
*::-ms-backdrop, .breadcrumb-4 li{
   display :-ms-inline-flexbox;
}
*::-ms-backdrop, body.horizontalmenu{
   flex-direction: column;
}
*::-ms-backdrop, .main-content {
    min-height: 85vh;
}
@media (min-width: 992px){
*::-ms-backdrop, .main-header.side-header{
    position: fixed;
   }
} 
/*---  IE  ---*/