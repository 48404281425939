/*---  Media  ---*/

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.main-media-list-activity {
  .media + .media {
    margin-top: 25px;
  }

  .media-icon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    border-radius: 100%;

    i {
      font-size: 21px;
      line-height: 0;

      &.typcn {
        line-height: .9;
      }
    }
  }

  .media-body {
    margin-left: 15px;

    h6 {
      margin-bottom: 2px;
      color: $color;
    }

    span {
      display: block;
      font-size: 11px;
      color: $muted;
    }
  }

  .media-right {
    font-size: 11px;
    color: $muted;
  }
}

.main-media-list-reviews {
  .media + .media {
    margin-top: 25px;
  }

  .main-img-user {
    width: 32px;
    height: 32px;

    &::after {
      display: none;
    }
  }

  .media-body {
    margin-left: 15px;

    h6 {
      margin-bottom: 2px;
      line-height: 1;
    }

    small {
      display: inline-block;
      font-size: 12px;
      color: $muted;
      line-height: 1;
    }
  }
}