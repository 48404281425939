/*---  Modal  ---*/

.modal-open {
  overflow: hidden;

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal {
  &.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
  }

  &.show .modal-dialog {
    transform: none;
  }
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);

  .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden;
  }

  .modal-header, .modal-footer {
    flex-shrink: 0;
  }

  .modal-body {
    overflow-y: auto;
  }
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);

  &::before {
    display: block;
    height: calc(100vh - 1rem);
    content: "";
  }

  &.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .modal-content {
      max-height: none;
    }

    &::before {
      content: none;
    }
  }
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid$border;
  border-radius: 0;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12), 0 8px 16px 0 rgba(0, 0, 0, 0.24);
  border-radius: 0;
  border-width: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: $color;
  background-color: $color;

  &.fade {
    opacity: 0;
  }

  &.show {
    opacity: 0.5;
    opacity: .8;
  }
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid$border;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  align-items: center;
  padding: 15px;

  .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
  }
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 700;
  color: $color;
  line-height: 1;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  padding: 25px;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid$border;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;

  > {
    :not(:first-child) {
      margin-left: .25rem;
    }

    :not(:last-child) {
      margin-right: .25rem;
    }
  }
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-content .close {
  font-size: 28px;
  padding: 0;
  margin: 0;
  line-height: .5;
}

.modal-header .modal-title {
  margin-bottom: 0;
}

.modal {
  &.animated .modal-dialog {
    transform: translate(0, 0);
  }

  &.effect-scale {
    .modal-dialog {
      transform: scale(0.7);
      opacity: 0;
      transition: all 0.3s;
    }

    &.show .modal-dialog {
      transform: scale(1);
      opacity: 1;
    }
  }

  &.effect-slide-in-right {
    .modal-dialog {
      transform: translateX(20%);
      opacity: 0;
      transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
    }

    &.show .modal-dialog {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &.effect-slide-in-bottom {
    .modal-dialog {
      transform: translateY(20%);
      opacity: 0;
      transition: all 0.3s;
    }

    &.show .modal-dialog {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &.effect-newspaper {
    .modal-dialog {
      transform: scale(0) rotate(720deg);
      opacity: 0;
    }

    &.show ~ .modal-backdrop, .modal-dialog {
      transition: all 0.5s;
    }

    &.show .modal-dialog {
      transform: scale(1) rotate(0deg);
      opacity: 1;
    }
  }

  &.effect-fall {
    -webkit-perspective: 1300px;
    -moz-perspective: 1300px;
    perspective: 1300px;

    .modal-dialog {
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
      transform: translateZ(600px) rotateX(20deg);
      opacity: 0;
    }

    &.show .modal-dialog {
      transition: all 0.3s ease-in;
      transform: translateZ(0px) rotateX(0deg);
      opacity: 1;
    }
  }

  &.effect-flip-horizontal {
    perspective: 1300px;

    .modal-dialog {
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
      transform: rotateY(-70deg);
      transition: all 0.3s;
      opacity: 0;
    }

    &.show .modal-dialog {
      transform: rotateY(0deg);
      opacity: 1;
    }
  }

  &.effect-flip-vertical {
    perspective: 1300px;

    .modal-dialog {
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
      transform: rotateX(-70deg);
      transition: all 0.3s;
      opacity: 0;
    }

    &.show .modal-dialog {
      transform: rotateX(0deg);
      opacity: 1;
    }
  }

  &.effect-super-scaled {
    .modal-dialog {
      transform: scale(2);
      opacity: 0;
      transition: all 0.3s;
    }

    &.show .modal-dialog {
      transform: scale(1);
      opacity: 1;
    }
  }

  &.effect-sign {
    perspective: 1300px;

    .modal-dialog {
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
      transform: rotateX(-60deg);
      transform-origin: 50% 0;
      opacity: 0;
      transition: all 0.3s;
    }

    &.show .modal-dialog {
      transform: rotateX(0deg);
      opacity: 1;
    }
  }

  &.effect-rotate-bottom {
    perspective: 1300px;

    .modal-dialog {
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
      transform: translateY(100%) rotateX(90deg);
      transform-origin: 0 100%;
      opacity: 0;
      transition: all 0.3s ease-out;
    }

    &.show .modal-dialog {
      transform: translateY(0%) rotateX(0deg);
      opacity: 1;
    }
  }

  &.effect-rotate-left {
    perspective: 1300px;

    .modal-dialog {
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;
      transform: translateZ(100px) translateX(-30%) rotateY(90deg);
      transform-origin: 0 100%;
      opacity: 0;
      transition: all 0.3s;
    }

    &.show .modal-dialog {
      transform: translateZ(0px) translateX(0%) rotateY(0deg);
      opacity: 1;
    }
  }

  &.effect-just-me {
    .modal-dialog {
      transform: scale(0.8);
      opacity: 0;
      transition: all 0.3s;
    }

    .close {
      text-shadow: none;
      color: $white;
    }

    .modal-header {
      background-color: transparent;
      border-bottom-color: rgba(255, 255, 255, 0.1);

      h6 {
        color: $white;
        font-weight: 500;
      }
    }

    .modal-content {
      background: #000;
    }

    .modal-body {
      color: rgba(255, 255, 255, 0.8);

      h6 {
        color: $white;
      }
    }

    .modal-footer {
      background-color: transparent;
      border-top-color: rgba(255, 255, 255, 0.1);
    }

    &.show {
      ~ .modal-backdrop {
        opacity: .96;
      }

      .modal-dialog {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}

.main-modal-calendar-schedule {
  .modal-content {
    border-width: 0;
    position: relative;

    > .close {
      position: absolute;
      top: 20px;
      right: 25px;
      font-size: 28px;
      font-weight: 700;
      color: $muted;
      text-shadow: none;
    }
  }

  .modal-header {
    padding: 20px 25px;
  }

  .modal-body {
    padding: 30px 25px;
  }

  .form-group-date {
    display: flex;
    align-items: center;
    border: 1px solid$border;
    background-color: #f1f2f9;
    padding: 10px 5px;
    color: $color;
    font-size: 13px;
    font-weight: 600;

    > i {
      font-size: 32px;
      line-height: 0;
      margin-right: 10px;
      opacity: .5;
    }
  }

  .select2-container {
    width: 100% !important;
  }
}

.main-modal-calendar-event {
  .modal-content {
    border-width: 0;
    position: relative;
    background-color: transparent;

    > .close {
      font-size: 18px;
      text-shadow: none;
    }
  }

  .modal-header {
    position: relative;
    padding: 20px;
    border-bottom-width: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .nav-modal-event {
    align-items: center;

    .nav-link {
      padding: 0;
      color: rgba(255, 255, 255, 0.75);
      font-size: 21px;
      line-height: 0;
      position: relative;
      transition: all 0.2s ease-in-out;

      &:hover, &:focus {
        color: $white;
      }

      + .nav-link {
        margin-left: 15px;
      }

      &:last-child {
        font-size: 24px;
      }
    }
  }

  .event-title {
    color: $white;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 1;
  }

  .modal-body {
    padding: 20px;
    background-color: $white;
  }

  .event-start-date, .event-end-date {
    color: $color;
    font-weight: 500;
  }

  .event-desc {
    margin-top: 5px;

    &:empty {
      margin-bottom: 5px;
    }
  }
}

.modal-content-demo .modal-body {
  h6 {
    color: $color;
    font-size: 15px;
    margin-bottom: 15px;
  }

  p {
    margin-bottom: 0;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);

    .modal-content {
      max-height: calc(100vh - 3.5rem);
    }
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);

    &::before {
      height: calc(100vh - 3.5rem);
    }
  }

  .modal-sm {
    max-width: 300px;
  }

  .modal-header {
    padding: 15px 20px;
  }

  .main-modal-calendar-event {
    .modal-header {
      padding: 20px 25px;
    }

    .modal-body {
      padding: 30px;
    }
  }
}

@media (min-width: 992px) {
  .modal-header {
    padding: 20px;
  }

  .modal-lg, .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-header {
    padding: 20px 25px;
  }

  .modal-xl {
    max-width: 1140px;
  }
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog, .main-modal-calendar-event .nav-modal-event .nav-link {
    transition: none;
  }
}