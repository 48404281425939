/*---  Profile  ---*/

.main-profile-menu {
  position: relative;
  padding: 0.5rem 0.5rem;

  > .main-img-user {
    outline: none;
    width: 32px;
    height: 32px;

    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    padding-top: 0px;
    position: absolute;
    top: 53px;
    left: auto;
    right: -10px;
    bottom: auto;
    width: 230px;
    border-color:$border;
    border-width: 1px;
    box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
  }

  .dropdown-item {
    position: relative;
    padding: 10px;
    height: 38px;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #596882;

    i {
      font-size: 16px;
      margin-right: 10px;
      width: 24px;
      text-align: center;
      line-height: .9;
    }

    &:hover, &:focus {
      background-color: transparent;
      color: $primary;
    }

    + .dropdown-item {
      border-top: 1px solid$border;
    }
  }

  &.show {
    .main-img-user::before, .dropdown-menu {
      display: block;
    }
  }
}

.main-profile-overview {
  .main-img-user {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
    margin: 0 auto;

    &::after {
      display: none;
    }
  }

  .btn-icon-list {
    .btn, .sp-container button {
      border-radius: 100%;
    }
  }
}

.sp-container .main-profile-overview .btn-icon-list button {
  border-radius: 100%;
}

.main-profile-name {
  color: $color;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 2px;
}

.main-profile-name-text {
  color: $muted;
  font-size: 13px;
  margin-bottom: 0;
}

.main-profile-bio {
  font-size: 13px;
  margin-bottom: 20px;
}

.main-profile-social-list {
  .media {
    align-items: center;

    + .media {
      margin-top: 20px;
    }
  }

  .media-icon {
    font-size: 34px;
    width: 30px;
    line-height: 0;
  }

  .media-body {
    margin-left: 20px;

    span {
      display: block;
      font-size: 12px;
    }

    a {
      font-size: 13px;
    }
  }
}

.main-profile-body {
  padding: 15px 0 0;
}

.main-profile-view-chart {
  position: relative;
  width: calc(100% - 10px);
  height: 200px;
}

.main-profile-view-info {
  position: absolute;
  top: 0;
  left: 0;

  h6 {
    font-size: 32px;
    font-weight: 500;
    color: $color;
    margin-bottom: 0;
  }

  span {
    font-size: 12px;
    color: $pink;
    margin-left: 5px;
  }

  p {
    font-size: 13px;
    margin-bottom: 0;
  }
}

.main-profile-work-list {
  .media + .media {
    margin-top: 25px;
  }

  .media-logo {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    color: $white;
    font-size: 21px;
    position: relative;
    top: 2px;
    border-radius: 100%;
  }

  .media-body {
    margin-left: 20px;

    h6 {
      color: $color;
      font-weight: 500;
      margin-bottom: 2px;
    }

    span {
      display: block;
      margin-bottom: 5px;
    }

    p {
      margin-bottom: 0;
      font-size: 12px;
      color: $muted;
    }
  }
}

.main-profile-contact-list {
  .media {
    align-items: center;

    + .media {
      margin-top: 25px;
    }
  }

  .media-icon {
    line-height: 0;
    font-size: 22px;
    width: 35px;
    text-align: center;
  }

  .media-body {
    margin-left: 25px;

    span {
      font-size: 12px;
      color: $muted;
      display: block;
      line-height: 1.3;
    }

    div {
      font-weight: 500;
      color: $color;
    }
  }
}

.main-profile-menu .dropdown-menu .dropdown-item:first-child {
  border-top: 1px solid$border;
}

@media (min-width: 375px) {
  .main-profile-view-chart {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .main-profile-body {
    padding-left: 25px;
  }
}

@media (min-width: 992px) {
  .main-profile-body {
    padding: 25px 0 0 20px;
  }
}

@media (min-width: 576px) {
  .main-profile-body {
    padding-top: 20px;
  }

  .main-profile-view-chart {
    height: 250px;
  }
}