/*---  Sidebar  ---*/

.main-sidebar {
  width: 240px;
  flex-direction: column;
  flex-shrink: 0;
  border-right: 0 solid$border;
  transform: translateX(-240px);
  visibility: hidden;
  transition: all 0.4s;
}

.main-sidebar-sticky {
  position: fixed;
  top: 0px;
  left: 0;
  bottom: 0;
  background: $white;
  box-shadow: 0 8px 24px rgba(61, 119, 180, 0.12);
  transition: left 0.3s ease, width 0.3s ease;
  border-right: 1px solid $border;
}

.main-sidebar-header {
  flex-shrink: 0;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.main-sidebar-loggedin {
  padding: 10px 20px;
  display: flex;
  flex-shrink: 0;

  .main-img-user {
    width: 36px;
    height: 36px;
  }

  .media-body {
    margin-left: 12px;

    h6 {
      font-weight: 700;
      font-size: 14px;
      color: $color;
      margin-bottom: 2px;
    }

    span {
      display: block;
      color: $muted;
    }
  }
}

.main-sidebar-body {
  padding: 10px;

  .nav {
    flex-direction: column;
  }

  .nav-label {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: .5px;
    color: $muted;
    padding: 15px 0 0px 0;
  }

  .nav-item {
    position: relative;
    display: block;

    &.active .nav-link {
      color: $primary;
    }

    + .nav-item {
      margin-top: -1px;
    }
  }

  .nav-link {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0;
    font-weight: 400;
    font-size: 14px;
    color: $color;
    margin-left: 10px;

    i {
      font-size: 18px;
      line-height: 0;
      margin-right: 5px;
      width: 24px;

      &.typcn {
        line-height: .9;

        &::before {
          width: auto;
        }
      }
    }

    &.with-sub {
      position: relative;
      transition: all .25s;
    }
  }

  .nav-sub {
    list-style: none;
    padding: 0;
    margin-left: 36px;

    .nav-sub-link:before {
      content: '\e0b1';
      font-family: 'typicons';
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      top: 8px;
      font-size: 11px;
      margin-right: 8px;
      color: #68798b;
    }
  }

  .nav-sub-item {
    .nav-sub {
      padding: 0 0 0 12px;
      border-left-width: 1px;
      margin-left: 0;

      .nav-sub-link {
        height: auto;
        padding: 7px 0;
      }
    }

    &.active > .nav-sub-link {
      color: $primary;
    }

    &.show .nav-sub {
      display: block;
    }
  }

  .nav-sub-link {
    height: 30px;
    display: flex;
    align-items: center;
    color: $color;
    font-size: 13px;

    &:hover, &:focus {
      color: $primary;
    }

    &.with-sub {
      justify-content: space-between;

      &::after {
        content: '\f3d0';
        font-family: 'Ionicons';
        font-size: 12px;
        font-weight: 400;
        display: inline-block;
        position: relative;
        margin-left: auto;
        color:$border;
      }
    }

    + .nav-sub-link {
      border-top: 1px dotted$border;
    }
  }
}

@media (min-width: 992px) {
  .main-sidebar-hide {
    .main-sidebar {
      display: none;
    }

    .main-sidebar-sticky + .main-content {
      margin-left: 0;
    }
  }

  .main-sidebar {
    position: relative;
    display: flex;
    transform: none;
    z-index: 9999;
    border-right-width: 1px;
    visibility: visible;
  }

  .main-sidebar-sticky + .main-content {
    margin-left: 220px;
  }

  .main-sidebar-body .nav-sub-link.with-sub::after {
    margin-left: 4px;
    font-size: 12px;
  }
}

@media (min-width: 1200px) {
  .main-sidebar-sticky + .main-content {
    margin-left: 240px;
  }

  .main-sidebar-body {
    padding: 12px;
  }
}

@media (min-width: 768px) {
  .main-sidebar-show {
    overflow: inherit;

    .main-sidebar {
      display: flex;
      transform: translateX(0);
      visibility: visible;
      transition: left 0.3s ease, width 0.3s ease;
    }

    .main-navbar-backdrop {
      visibility: visible;
      opacity: 1;
    }
  }

  .main-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
    background-color: $white;
    transition: none;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .main-sidebar {
    width: 220px;
  }
}