/*---  Progress  ---*/

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $white;
  text-align: center;
  white-space: nowrap;
  background-color: $primary;
  transition: width 0.6s ease;
  height: 10px;
  font-size: 11px;
}

.progress-bar-xs {
  height: 5px;
}

.progress, .progress-bar {
  border-radius: 0;
}

.progress-bar-sm {
  height: 8px;
}

.progress-bar-lg {
  height: 15px;
}

.progress {
  vertical-align: baseline;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color:$border;
  border-radius: 3px;
  height: auto;
}


.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }

  .progress-bar-animated {
    animation: none;
  }
}