/*---  Custom-pages  ---*/

.main-signin {
  height: 560px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.5);
}

.main-signin-header {
  h2 {
    font-weight: 500;
    color: $primary;
    letter-spacing: -1px;
  }

  h4 {
    font-weight: 400;
    color: $color;
    margin-bottom: 25px;
  }

  label {
    color: $muted;
  }

  .form-control {
    color: $color;
    font-weight: 500;
    border-width: 2px;
    border-color:$border;

    &:focus {
      border-color: #b4bdce;
      box-shadow: none;
    }

    &::placeholder {
      font-weight: 400;
      color: $muted;
    }
  }

  .btn, .sp-container button {
    margin-top: 25px;
  }
}

.sp-container .main-signin-header button {
  margin-top: 25px;
}

.main-signin-footer {
  p {
    color: $muted;

    &:first-child {
      margin-bottom: 5px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $color;
    font-weight: 700;

    &:hover, &:focus {
      color: $primary;
    }
  }
}

.main-signup-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  background-color: rgba(244, 245, 248, 0.2);
}

.main-column-signup-left {
  flex: 1;
  padding: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: none;

  > div {
    max-width: 500px;
  }

  .typcn {
    font-size: 80px;
    margin-bottom: 50px;

    &::before {
      width: auto;
    }
  }

  .main-logo {
    font-size: 40px;
    line-height: 1;
  }

  h5 {
    color: $color;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 25px;
  }

  p {
    line-height: 1.7;
    margin-bottom: 25px;
  }

  .btn, .sp-container button {
    border-width: 2px;
    padding-left: 25px;
    padding-right: 25px;
    font-weight: 700;
    text-transform: uppercase;
    height: 38px;
  }
}

.sp-container .main-column-signup-left button {
  border-width: 2px;
  padding-left: 25px;
  padding-right: 25px;
  font-weight: 700;
  text-transform: uppercase;
  height: 38px;
}

.main-column-signup {
  background-color: $white;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main-signup-header {
  h2 {
    font-weight: 500;
    color: $primary;
    letter-spacing: -1px;
  }

  h4 {
    font-size: 20px;
    font-weight: 400;
    color: $color;
    margin-bottom: 25px;
  }

  label {
    color: $muted;
  }

  .form-control {
    color: $color;
    font-weight: 500;
    border-width: 2px;
    border-color:$border;

    &:focus {
      border-color: #b4bdce;
      box-shadow: none;
    }

    &::placeholder {
      font-weight: 400;
      color: $muted;
    }
  }

  > .btn {
    margin-top: 25px;
  }
}

.sp-container .main-signup-header > button {
  margin-top: 25px;
}

.main-signup-header .row {
  margin-top: 20px;

  > div {
    .btn i, .sp-container button i {
      font-size: 15px;
      line-height: 0;
      margin-right: 5px;
    }
  }
}

.sp-container .main-signup-header .row > div button i {
  font-size: 15px;
  line-height: 0;
  margin-right: 5px;
}

.main-signup-header .row > div:first-child {
  .btn, .sp-container button {
    background-color: #4267b2;
    color: $white;
  }
}

.sp-container .main-signup-header .row > div:first-child button {
  background-color: #4267b2;
  color: $white;
}

.main-signup-header .row > div:first-child {
  .btn:hover, .sp-container button:hover {
    background-color: #375694;
    color: $white;
  }
}

.sp-container .main-signup-header .row > div:first-child button:hover {
  background-color: #375694;
  color: $white;
}

.main-signup-header .row > div:first-child {
  .btn:focus, .sp-container button:focus {
    background-color: #375694;
    color: $white;
  }
}

.sp-container .main-signup-header .row > div:first-child button:focus {
  background-color: #375694;
  color: $white;
}

.main-signup-footer {
  p {
    color: $muted;
    margin-bottom: 0;
  }

  a {
    color: $color;
    font-weight: 700;

    &:hover, &:focus {
      color: $primary;
    }
  }
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@media (min-width: 480px) {
  .main-card-signin {
    border: 1px solid$border;
    width: 360px;
    padding: 30px 35px;
  }
}
