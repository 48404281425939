/*---  Main-home  ---*/

.main-home-slider {
  position: relative;
  z-index: 4;
  width: 100%;
  height: 600px;
  max-height: 1800px;
  overflow: hidden;
  background-color: #1904be;

  .chart-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -20px;
    z-index: 3;
    display: flex;
    align-items: flex-end;
    opacity: .2;

    .flot-chart {
      width: 100%;
      height: 400px;
    }
  }

  .card-columns {
    column-gap: 5px;
    column-count: 3;
    width: 500px;
    transform: rotate(37deg);
    position: absolute;
    top: 9%;
    left: 24%;
    z-index: 4;
    opacity: .25;

    .card {
      position: relative;
      border-width: 0;
      background-color: transparent;
      margin-bottom: 5px;
    }
  }
}

.main-home-content {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 5;
  color: rgba(255, 255, 255, 0.7);
  right: 20px;

  .main-logo {
    font-size: 30px;
    font-weight: 700;
    display: flex;
    line-height: 1;
    color: $white;
    margin-bottom: 5px;
    text-indent: -1px;
    color: $white;
    letter-spacing: -.5px;
  }

  h5 {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: .2px;
    margin-bottom: 25px;
  }

  .logo-group {
    margin-bottom: 60px;
    display: flex;

    i {
      font-weight: 400;
      font-size: 32px;
      line-height: .2;

      + i {
        margin-left: 10px;
      }
    }
  }

  h1 {
    color: $white;
    font-weight: 300;
    font-size: 24px;
    margin-bottom: 20px;
  }

  p {
    &:first-of-type {
      font-size: 14px;
      line-height: 1.7;
      margin-bottom: 25px;
    }

    &:last-of-type {
      display: flex;
      margin-bottom: 100px;

      .btn, .sp-container button {
        flex: 1;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 500;
        min-height: inherit;
        letter-spacing: 1px;
        padding: 10px 15px;
      }
    }
  }
}

.sp-container .main-home-content p:last-of-type button {
  flex: 1;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  min-height: inherit;
  letter-spacing: 1px;
  padding: 10px 15px;
}

.main-home-content p:last-of-type {
  .btn + .btn, .sp-container button + .btn {
    margin-left: 5px;
  }
}

.sp-container .main-home-content p:last-of-type button + .btn, .main-home-content p:last-of-type .sp-container .btn + button, .sp-container .main-home-content p:last-of-type .btn + button, .main-home-content p:last-of-type .sp-container button + button, .sp-container .main-home-content p:last-of-type button + button {
  margin-left: 5px;
}

.main-home-content nav:last-child {
  display: flex;

  a {
    font-size: 24px;
    color: rgba(255, 255, 255, 0.5);

    &:hover, &:focus {
      color: $white;
    }

    + a {
      margin-left: 8px;
    }
  }
}

@media (min-width: 1300px) {
  .main-home-slider .card-columns {
    right: -5%;
  }
}

@media (min-width: 576px) {
  .main-home-slider {
    height: 600px;

    .card-columns {
      width: 120%;
      left: 16%;
      transform: rotate(44deg);
    }
  }

  .main-home-content {
    top: 40px;
    left: 40px;
    right: auto;
    width: 400px;

    .main-logo {
      font-size: 36px;
      letter-spacing: -1px;
    }

    h5 {
      font-size: 15px;
    }

    h1 {
      font-size: 2.03125rem;
    }
  }
}

@media (min-width: 992px) {
  .main-home-slider {
    .chart-wrapper .flot-chart {
      height: 500px;
    }

    .card-columns {
      width: 80%;
      top: -10%;
      right: -30%;
      column-gap: 10px;
      margin-bottom: 10px;
    }
  }

  .main-home-content p:last-of-type {
    .btn, .sp-container button {
      flex: 1;
    }
  }

  .sp-container .main-home-content p:last-of-type button {
    flex: 1;
  }
}

@media (min-width: 1200px) {
  .main-home-slider {
    height: 100vh;
    max-height: 780px;

    .card-columns {
      width: 70%;
      right: -15%;
      top: -15%;
    }
  }
}

@media (min-width: 768px) {
  .main-home-slider {
    .chart-wrapper {
      opacity: 1;
    }

    .card-columns {
      transform: rotate(45deg);
      width: 100%;
      top: 0;
      left: auto;
      right: -45%;
      opacity: 1;
    }
  }

  .main-home-content {
    width: 410px;

    p:last-of-type {
      .btn, .sp-container button {
        padding: 10px 25px;
        flex: none;
      }
    }
  }

  .sp-container .main-home-content p:last-of-type button {
    padding: 10px 25px;
    flex: none;
  }
}

@media (min-width: 1440px) {
  .main-home-slider {
    max-height: 900px;
  }
}

@media (min-width: 1680px) {
  .main-home-slider {
    max-height: 1050px;
  }
}

@media (min-width: 1920px) {
  .main-home-slider {
    max-height: 1200px;
  }
}

@media (min-width: 2560px) {
  .main-home-slider {
    max-height: 1600px;
  }
}

@media (min-width: 2880px) {
  .main-home-slider {
    max-height: 1800px;
  }
}