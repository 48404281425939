/*---  Cards  ---*/

.card-group {
  display: flex;
  flex-direction: column;

  > .card {
    margin-bottom: 15px;
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $white;
  background-clip: border-box;
  border: 1px solid$border;
  border-radius: 3px;
  border-radius: 0;

  > {
    hr {
      margin-right: 0;
      margin-left: 0;
    }

    .list-group {
      &:first-child .list-group-item:first-child {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }

      &:last-child .list-group-item:last-child {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }
  }
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
  font-weight: 500;
  color: $color;
  font-size: 15px;
  line-height: 1;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link {
  &:hover {
    text-decoration: none;
  }

  + .card-link {
    margin-left: 1.25rem;
  }
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  border-bottom: 1px solid$border;

  &:first-child {
    border-radius: calc(3px - 1px) calc(3px - 1px) 0 0;
    border-radius: 0;
  }

  + .list-group .list-group-item:first-child {
    border-top: 0;
  }
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background: $white;
  border-top: 1px solid$border;

  &:last-child {
    border-radius: 0 0 calc(3px - 1px) calc(3px - 1px);
  }
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(3px - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(3px - 1px);
  border-top-right-radius: calc(3px - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(3px - 1px);
  border-bottom-left-radius: calc(3px - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;

  .card {
    margin-bottom: 15px;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

.card {
  &.text-white .card-title, &.tx-white .card-title {
    color: $white;
  }
}

.card-header, .card-footer {
  position: relative;
  border-color:$border;
  padding-left: 15px;
  padding-right: 15px;
}

.card-header-tab {
  border-bottom: 0;
  padding: 0;
}

.card-invoice {
  background-color: transparent;
  border-width: 0;

  .card-body {
    padding: 20px;
  }
}

.card-dashboard-twentyfour {
  .card-title {
    font-size: 14px;
    margin-bottom: 0;
  }

  .card-body {
    padding: 15px 20px 20px;
  }

  .card-body-top {
    display: flex;
    margin-bottom: 20px;

    h6 {
      color: $color;
      font-size: 18px;
      font-weight: 600;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      margin-bottom: 0;
      letter-spacing: -.75px;

      small {
        font-weight: 600;
      }

      span {
        color: $muted;
        font-weight: 400;
        letter-spacing: normal;
      }
    }

    label {
      display: block;
      margin-bottom: 0;
      color: $muted;
      font-size: 11px;
    }

    > div + div {
      margin-left: 30px;
    }
  }

  .main-content-label, .card-table-two .card-title {
    font-size: 10px;
    color: $color;
    letter-spacing: .5px;
    margin-bottom: 0;
  }
}

.card-table-two .card-dashboard-twentyfour .card-title, .card-dashboard-twentyfour .card-dashboard-eight .card-title, .card-dashboard-eight .card-dashboard-twentyfour .card-title {
  font-size: 10px;
  color: $color;
  letter-spacing: .5px;
  margin-bottom: 0;
}

.card-dashboard-twentyfour {
  .chart-legend {
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .5px;
    line-height: .6;

    span {
      display: inline-block;
      width: 7px;
      height: 7px;
      margin-right: 5px;
    }

    > div {
      display: flex;
      align-items: center;

      + div {
        margin-left: 20px;
      }
    }
  }

  .chart-wrapper {
    position: relative;
    margin-right: -10px;
  }

  .flot-chart {
    width: 100%;
    height: 130px;
  }
}

.card-dashboard-twentyfive {
  .card-title {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .col + .col {
    border-left: 1px solid$border;
  }

  .card-label {
    display: block;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: .5px;
    margin-bottom: 2px;
    color: $muted;
    white-space: nowrap;
  }

  .card-value {
    font-size: 22px;
    font-weight: 600;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: $color;
    margin-bottom: 0;
    letter-spacing: -1px;
  }

  .chart-wrapper {
    position: relative;
    margin: 0 -8px -5px;
  }

  .flot-chart {
    width: 100%;
    height: 35px;
  }
}

.card-dashboard-twentysix {
  .card-header {
    padding: 15px 15px 10px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .card-title {
    margin-bottom: 0;
    text-transform: uppercase;
  }

  .chart-legend {
    display: flex;
    align-items: center;

    > div {
      font-size: 10px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: .5px;

      + div {
        margin-left: 15px;
      }
    }

    span {
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 2px;
    }
  }

  .card-body {
    padding: 0;
    position: relative;
    overflow: hidden;

    h6 {
      margin-bottom: 0;
      color: $color;
      font-size: 22px;
      font-weight: 600;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      letter-spacing: -.5px;
      line-height: 1;

      span {
        font-weight: 400;
        font-size: 18px;
        letter-spacing: -1px;
      }
    }

    label {
      font-size: 12px;
      margin-bottom: 0;
      color: $muted;
    }
  }

  .chart-wrapper {
    position: relative;
    margin: -30px -18px -15px -18px;
  }

  .flot-chart {
    width: 100%;
    height: 120px;

    .flot-x-axis > div {
      font-size: 10px;
      letter-spacing: .5px;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.75);
      transform: translateY(-20px);
    }

    .flot-y-axis > div {
      font-size: 10px;
      transform: translateX(33px);
      color: rgba(28, 39, 60, 0.5);
    }
  }

  &.card-dark-one .card-title, &.card-dark-two .card-title, &.card-dark-one .card-body h6, &.card-dark-two .card-body h6 {
    color: $white;
  }

  &.card-dark-one .card-body h6 span, &.card-dark-two .card-body h6 span {
    color: rgba(255, 255, 255, 0.5);
  }

  &.card-dark-one .card-body label, &.card-dark-two .card-body label {
    color: rgba(255, 255, 255, 0.7);
  }

  &.card-dark-one .flot-chart .flot-y-axis > div, &.card-dark-two .flot-chart .flot-y-axis > div {
    color: rgba(255, 255, 255, 0.2);
  }

  &.card-dark-one {
    background-color: #7c59e6;
    background-image: linear-gradient(to bottom, #7c59e6 0%, #7c59e6 100%);
    background-repeat: repeat-x;
  }

  &.card-dark-two {
    background-color: #7c59e6;
    background-image: linear-gradient(to bottom, #0a47ff 0%, $primary 100%);
    background-repeat: repeat-x;
  }
}

@media (min-width: 1300px) {
  .card-dashboard-twentyfour .card-header {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 0;
  }
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;

    .card {
      display: flex;
      flex: 1 0 0%;
      flex-direction: column;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px;
    }
  }

  .card-group {
    flex-flow: row wrap;

    > .card {
      flex: 1 0 0%;
      margin-bottom: 0;

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        .card-img-top, .card-header {
          border-top-right-radius: 0;
        }

        .card-img-bottom, .card-footer {
          border-bottom-right-radius: 0;
        }
      }

      &:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        .card-img-top, .card-header {
          border-top-left-radius: 0;
        }

        .card-img-bottom, .card-footer {
          border-bottom-left-radius: 0;
        }
      }
    }
  }

  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block;
      width: 100%;
    }
  }
}

@media (min-width: 576px) {
  .card-dashboard-twentyfour .card-body-top {
    h6 {
      font-size: 22px;
    }

    label {
      font-size: 0.875rem;
    }
  }
}

@media (min-width: 992px) {
  .card-invoice .card-body {
    padding: 0 50px 50px;
  }
}