	

/* ########## bootstrap OVERRIDES ########## */

@import "variables";

@import "bootstrap/accordion";
@import "bootstrap/alert";
@import "bootstrap/badge";
@import "bootstrap/bootstrap styles";
@import "bootstrap/breadcrumb";
@import "bootstrap/button";
@import "bootstrap/card";
@import "bootstrap/carousel";
@import "bootstrap/dropdowns";
@import "bootstrap/input-group";
@import "bootstrap/list";
@import "bootstrap/main-home";
@import "bootstrap/media";
@import "bootstrap/modal";
@import "bootstrap/navigation";
@import "bootstrap/pagination";
@import "bootstrap/popover";
@import "bootstrap/progress";
@import "bootstrap/tooltip";


/* ############### COMPONENTS ############### */
@import "components/chat";
@import "components/colorpicker";
@import "components/datepicker";
@import "components/jqvmap";
@import "components/Profile";
@import "components/rangesilder";
@import "components/rating";
@import "components/spinner";
@import "components/tables";
@import "components/select";


/* ########## CUSTOM  STYLES ########## */

@import "custom/font/font";
@import "custom/custom-control";
@import "custom/custom-pages";
@import "custom/custom-styles";
@import "custom/avatar";


/* ############### LAYOUTS STYLES ############### */
@import "layouts/container";
@import "layouts/footer";
@import "layouts/grid";
@import "layouts/header";
@import "layouts/main menu";
@import "layouts/saidebar";


/* ############### LIBRARY ############### */
@import "library/calendar";
@import "library/checkbox";
@import "library/contact";
@import "library/content";
@import "library/form-editor";
@import "library/forms";
@import "library/form-wizard";
@import "library/iconbar";
@import "library/invoice";
@import "library/mail";
@import "library/radiobox";
@import "library/table";


/* ############### UTILITIES CLASSES ############### */
@import "utlities/alingments";
@import "utlities/background";
@import "utlities/border";
@import "utlities/disply";
@import "utlities/height";
@import "utlities/marrgin";
@import "utlities/opacity";
@import "utlities/padding";
@import "utlities/potitions";
@import "utlities/trypography";
@import "utlities/width";
