/*---  Tables  ---*/
  
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: $color;
  color: $color;

  th, td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid$border;
    padding: 9px 15px;
    line-height: 1.462; 
  }
  .dataTable {
    th, td {
      border-left: 1px solid$border;
    }
  }
 .table-bordered {
  border: 1px solid$border;
  th, td {
    border: 1px solid$border;
  }
  thead {
    th, td {
      border-bottom-width: 2px;
      border-top-width: 1px;
      padding-top: 7px;
      padding-bottom: 7px;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
}
  thead th {
    vertical-align: bottom;
    border-bottom: 1px solid$border;
  }

  tbody + tbody {
    border-top: 1px solid$border;
  }
}

.table-bordered th, .table-bordered td {
  border: 1px solid #e1e6f1;
}

.table-sm {
  th, td {
    padding: 0.3rem;
  }
}
.table-borderless {
  th, td, thead th, tbody + tbody {
    border: 0;
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: $color;
  background-color: #f1f2f9;
}

.table-primary {
  background-color: #c6d4ff;

  > {
    th, td {
      background-color: #c6d4ff;
    }
  }

  th, td, thead th, tbody + tbody {
    border-color: #95afff;
  }
}

.table-hover .table-primary:hover {
  background-color: #adc1ff;

  > {
    td, th {
      background-color: #adc1ff;
    }
  }
}

.table-secondary {
  background-color: #d9dde5;

  > {
    th, td {
      background-color: #d9dde5;
    }
  }

  th, td, thead th, tbody + tbody {
    border-color: #b9c1ce;
  }
}

.table-hover .table-secondary:hover {
  background-color: #cacfdb;

  > {
    td, th {
      background-color: #cacfdb;
    }
  }
}

.table-success {
  background-color: #c8e9b8;

  > {
    th, td {
      background-color: #c8e9b8;
    }
  }

  th, td, thead th, tbody + tbody {
    border-color: #99d67b;
  }
}

.table-hover .table-success:hover {
  background-color: #b9e3a5;

  > {
    td, th {
      background-color: #b9e3a5;
    }
  }
}

.table-info {
  background-color: #bee5eb;

  > {
    th, td {
      background-color: #bee5eb;
    }
  }

  th, td, thead th, tbody + tbody {
    border-color: #86cfda;
  }
}

.table-hover .table-info:hover {
  background-color: #abdde5;

  > {
    td, th {
      background-color: #abdde5;
    }
  }
}

.table-warning {
  background-color: #ffeeba;

  > {
    th, td {
      background-color: #ffeeba;
    }
  }

  th, td, thead th, tbody + tbody {
    border-color: #ffdf7e;
  }
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;

  > {
    td, th {
      background-color: #ffe8a1;
    }
  }
}

.table-danger {
  background-color: #f5c6cb;

  > {
    th, td {
      background-color: #f5c6cb;
    }
  }

  th, td, thead th, tbody + tbody {
    border-color: #ed969e;
  }
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;

  > {
    td, th {
      background-color: #f1b0b7;
    }
  }
}

.table-light {
  background-color: #fcfcfd;

  > {
    th, td {
      background-color: #fcfcfd;
    }
  }

  th, td, thead th, tbody + tbody {
    border-color: #f9fafb;
  }
}

.table-hover .table-light:hover {
  background-color: #ededf3;

  > {
    td, th {
      background-color: #ededf3;
    }
  }
}

.table-dark {
  background-color: #c8ccd3;

  > {
    th, td {
      background-color: #c8ccd3;
    }
  }

  th, td, thead th, tbody + tbody {
    border-color: #99a0ae;
  }
}

.table-hover .table-dark:hover {
  background-color: #babfc8;

  > {
    td, th {
      background-color: #babfc8;
    }
  }
}

.table-active {
  background-color: rgba(0, 0, 0, 0.075);

  > {
    th, td {
      background-color: rgba(0, 0, 0, 0.075);
    }
  }
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);

  > {
    td, th {
      background-color: rgba(0, 0, 0, 0.075);
    }
  }
}

.table {
  .thead-dark th {
    color: $white;
    background-color: #3b4863;
    border-color: #49597b;
  }

  .thead-light th {
    color: #596882;
    background-color:$border;
    border-color:$border;
  }
}

.table-dark {
  color: $white;
  background-color: #3b4863;

  th, td, thead th {
    border-color: #49597b;
  }

  &.table-bordered {
    border: 0;
  }

  &.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
  }

  &.table-hover tbody tr:hover {
    color: $white;
    background-color: rgba(255, 255, 255, 0.075);
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  > .table-bordered {
    border: 0;
  }
}

.main-table-reference {
  margin-top: 40px;
  background-color: $white;

  > {
    thead > tr > {
      th, td {
        padding: 8px 10px;
        border: 1px solid$border;
        font-size: 13px;
      }
    }

    tbody > tr > {
      th, td {
        padding: 8px 10px;
        border: 1px solid$border;
        font-size: 13px;
      }
    }

    thead > tr > {
      th, td {
        background-color: #eef0fb;
        color: #4b4f56;
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
        border-bottom: 0;
      }
    }
  }
}

table.dataTable {
  border: 1px solid$border;

  &.no-footer {
    border-bottom-color:$border;
  }

  thead {
    th, td {
      border-top-width: 0;
      border-bottom-width: 0;
      padding: 8px 10px;
      font-weight: 700;
      font-size: 12px;
      color: $color;
    }
  }
}

.dataTables_wrapper {
  .dataTables_filter input {
    margin-left: 0;
    border: 1px solid$border;
  }

  .dataTables_paginate .paginate_button.current {
    border: 0;
    background-image: none;
    background-color: $primary;
    color: $white !important;

    &:hover, &:focus {
      border: 0;
      background-image: none;
      background-color: $primary;
      color: $white !important;
    }
  }
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    > .table-bordered {
      border: 0;
    }
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    > .table-bordered {
      border: 0;
    }
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg, .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    > .table-bordered {
      border: 0;
    }
  }
}
.irs-min, .irs-max, .tree li i {
    color: #a1a3b0;
}
.tree li i {
    margin-right: 10px;
    font-weight: bold;
    font-size: 16px;
    line-height: 1;
    cursor: pointer;
}
