/*---  Input-Group  ---*/

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #596882;
  text-align: center;
  white-space: nowrap;
  background-color: #e5e9f3;
  border: 1px solid$border;
  border-radius: 03px;
  padding-top: 0;
  padding-bottom: 0;
  align-items: center;
  border-radius: 0;
}

.input-group > {
  .form-control, .form-control-plaintext, .custom-select, .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
  }

  .form-control + {
    .form-control, .custom-select, .custom-file {
      margin-left: -1px;
    }
  }

  .form-control-plaintext + {
    .form-control, .custom-select, .custom-file {
      margin-left: -1px;
    }
  }

  .custom-select + {
    .form-control, .custom-select, .custom-file {
      margin-left: -1px;
    }
  }

  .custom-file + {
    .form-control, .custom-select, .custom-file {
      margin-left: -1px;
    }
  }

  .form-control:focus, .custom-select:focus {
    z-index: 3;
    outline:0;
  }

  .custom-file .custom-file-input:focus {
    ~ .custom-file-label {
      z-index: 3;
    }

    z-index: 4;
  }
  
  .custom-file {
    display: flex;
    align-items: center;

    &:not(:last-child) .custom-file-label {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &::after {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.input-group-prepend, .input-group-append {
  display: flex;
  border-radius: 0;
}

.input-group-prepend {
  .btn, .sp-container button {
    position: relative;
    z-index: 0;
  }
}

.sp-container .input-group-prepend button {
  position: relative;
  z-index: 0;
}

.input-group-append {
  .btn, .sp-container button {
    position: relative;
    z-index: 0;
  }
}

.sp-container .input-group-append button {
  position: relative;
  z-index: 0;
}

.input-group-prepend {
  .btn:focus, .sp-container button:focus {
    z-index: 3;
  }
}

.sp-container .input-group-prepend button:focus {
  z-index: 3;
}

.input-group-append {
  .btn:focus, .sp-container button:focus {
    z-index: 3;
  }
}

.sp-container .input-group-append button:focus {
  z-index: 3;
}

.input-group-prepend {
  .btn + .btn, .sp-container button + .btn {
    margin-left: -1px;
  }
}

.sp-container .input-group-prepend button + .btn, .input-group-prepend .sp-container .btn + button, .sp-container .input-group-prepend .btn + button, .input-group-prepend .sp-container button + button, .sp-container .input-group-prepend button + button {
  margin-left: -1px;
}

.input-group-prepend {
  .btn + .input-group-text, .sp-container button + .input-group-text {
    margin-left: -1px;
  }
}

.sp-container .input-group-prepend button + .input-group-text {
  margin-left: -1px;
}

.input-group-prepend {
  .input-group-text + {
    .input-group-text, .btn {
      margin-left: -1px;
    }
  }

  .sp-container .input-group-text + button {
    margin-left: -1px;
  }
}

.sp-container .input-group-prepend .input-group-text + button {
  margin-left: -1px;
}

.input-group-append {
  .btn + .btn, .sp-container button + .btn {
    margin-left: -1px;
  }
}

.sp-container .input-group-append button + .btn, .input-group-append .sp-container .btn + button, .sp-container .input-group-append .btn + button, .input-group-append .sp-container button + button, .sp-container .input-group-append button + button {
  margin-left: -1px;
}

.input-group-append {
  .btn + .input-group-text, .sp-container button + .input-group-text {
    margin-left: -1px;
  }
}

.sp-container .input-group-append button + .input-group-text {
  margin-left: -1px;
}

.input-group-append {
  .input-group-text + {
    .input-group-text, .btn {
      margin-left: -1px;
    }
  }

  .sp-container .input-group-text + button {
    margin-left: -1px;
  }
}

.sp-container .input-group-append .input-group-text + button {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text input {
  &[type="radio"], &[type="checkbox"] {
    margin-top: 0;
  }
}

.input-group-lg > {
  .form-control:not(textarea), .custom-select {
    height: calc(1.5em + 1rem + 2px);
  }

  .form-control, .custom-select, .input-group-prepend > .input-group-text, .input-group-append > .input-group-text, .input-group-prepend > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0rem;
  }
}

.sp-container .input-group-lg > .input-group-prepend > button, .input-group-lg > .input-group-append > .btn, .sp-container .input-group-lg > .input-group-append > button {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0rem;
}

.input-group-sm > {
  .form-control:not(textarea), .custom-select {
    height: calc(1.5em + 0.5rem + 2px);
  }

  .form-control, .custom-select, .input-group-prepend > .input-group-text, .input-group-append > .input-group-text, .input-group-prepend > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.76562rem;
    line-height: 1.5;
    border-radius: 0rem;
  }
}

.sp-container .input-group-sm > .input-group-prepend > button, .input-group-sm > .input-group-append > .btn, .sp-container .input-group-sm > .input-group-append > button {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0rem;
}

.input-group-lg > .custom-select, .input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn, .sp-container .input-group > .input-group-prepend > button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > {
  .input-group-prepend > .input-group-text, .input-group-append:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.sp-container .input-group > .input-group-append:not(:last-child) > button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append {
  &:not(:last-child) > .input-group-text, &:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.sp-container .input-group > .input-group-append:last-child > button:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append {
  &:last-child > .input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.sp-container .input-group > .input-group-append > button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > {
  .input-group-append > .input-group-text, .input-group-prepend:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.sp-container .input-group > .input-group-prepend:not(:first-child) > button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .input-group-prepend {
  &:not(:first-child) > .input-group-text, &:first-child > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.sp-container .input-group > .input-group-prepend:first-child > button:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

input.parsley-error:focus, textarea.parsley-error:focus, input.parsley-success:focus, textarea.parsley-success:focus {
  box-shadow: none !important;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.parsley-checkbox {
  &.parsley-error {
    padding: 10px;
    border: 1px solid $danger;
  }

  &.parsley-success {
    padding: 10px;
    border: 1px solid $success;
  }
}

.parsley-errors-list {
  color: $danger;
  list-style: none;
  font-size: 12px;
  padding: 0;
  margin-bottom: 0;

  li {
    margin: 5px 0 0;
  }
}

.parsley-style-1 {
  .parsley-input {
    &.parsley-error .form-control {
      border-color: $danger;
      border-radius: 0;
    }

    &.parsley-success .form-control {
      border-color: $success;
    }
  }

  .parsley-checkbox.parsley-error {
    border: 1px solid $danger;
  }

  .parsley-select.parsley-error .select2-container--default .select2-selection--single {
    border-radius: 0;
  }

  .parsley-errors-list.filled {
    color: $danger;
    padding: 7px 10px;

    li:first-child {
      margin-top: 0;
    }
  }
}

/*---  Input-Group  ---*/

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #596882;
  text-align: center;
  white-space: nowrap;
  background-color: #e5e9f3;
  border: 1px solid$border;
  border-radius: 03px;
  padding-top: 0;
  padding-bottom: 0;
  align-items: center;
  border-radius: 0;
}

.input-group > {
  .form-control, .form-control-plaintext, .custom-select, .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
  }

  .form-control + {
    .form-control, .custom-select, .custom-file {
      margin-left: -1px;
    }
  }

  .form-control-plaintext + {
    .form-control, .custom-select, .custom-file {
      margin-left: -1px;
    }
  }

  .custom-select + {
    .form-control, .custom-select, .custom-file {
      margin-left: -1px;
    }
  }

  .custom-file + {
    .form-control, .custom-select, .custom-file {
      margin-left: -1px;
    }
  }

  .form-control:focus, .custom-select:focus {
    z-index: 3;
  }

  .custom-file .custom-file-input:focus {
    ~ .custom-file-label {
      z-index: 3;
    }

    z-index: 4;
  }

  .form-control:not(:last-child), .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .form-control:not(:first-child), .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .custom-file {
    display: flex;
    align-items: center;

    &:not(:last-child) .custom-file-label {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &::after {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.input-group-prepend, .input-group-append {
  display: flex;
  border-radius: 0;
}

.input-group-prepend {
  .btn, .sp-container button {
    position: relative;
    z-index: 0;
  }
}

.sp-container .input-group-prepend button {
  position: relative;
  z-index: 0;
}

.input-group-append {
  .btn, .sp-container button {
    position: relative;
    z-index: 0;
  }
}

.sp-container .input-group-append button {
  position: relative;
  z-index: 0;
}

.input-group-prepend {
  .btn:focus, .sp-container button:focus {
    z-index: 3;
  }
}

.sp-container .input-group-prepend button:focus {
  z-index: 3;
}

.input-group-append {
  .btn:focus, .sp-container button:focus {
    z-index: 3;
  }
}

.sp-container .input-group-append button:focus {
  z-index: 3;
}

.input-group-prepend {
  .btn + .btn, .sp-container button + .btn {
    margin-left: -1px;
  }
}

.sp-container .input-group-prepend button + .btn, .input-group-prepend .sp-container .btn + button, .sp-container .input-group-prepend .btn + button, .input-group-prepend .sp-container button + button, .sp-container .input-group-prepend button + button {
  margin-left: -1px;
}

.input-group-prepend {
  .btn + .input-group-text, .sp-container button + .input-group-text {
    margin-left: -1px;
  }
}

.sp-container .input-group-prepend button + .input-group-text {
  margin-left: -1px;
}

.input-group-prepend {
  .input-group-text + {
    .input-group-text, .btn {
      margin-left: -1px;
    }
  }

  .sp-container .input-group-text + button {
    margin-left: -1px;
  }
}

.sp-container .input-group-prepend .input-group-text + button {
  margin-left: -1px;
}

.input-group-append {
  .btn + .btn, .sp-container button + .btn {
    margin-left: -1px;
  }
}

.sp-container .input-group-append button + .btn, .input-group-append .sp-container .btn + button, .sp-container .input-group-append .btn + button, .input-group-append .sp-container button + button, .sp-container .input-group-append button + button {
  margin-left: -1px;
}

.input-group-append {
  .btn + .input-group-text, .sp-container button + .input-group-text {
    margin-left: -1px;
  }
}

.sp-container .input-group-append button + .input-group-text {
  margin-left: -1px;
}

.input-group-append {
  .input-group-text + {
    .input-group-text, .btn {
      margin-left: -1px;
    }
  }

  .sp-container .input-group-text + button {
    margin-left: -1px;
  }
}

.sp-container .input-group-append .input-group-text + button {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text input {
  &[type="radio"], &[type="checkbox"] {
    margin-top: 0;
  }
}

.input-group-lg > {
  .form-control:not(textarea), .custom-select {
    height: calc(1.5em + 1rem + 2px);
  }

  .form-control, .custom-select, .input-group-prepend > .input-group-text, .input-group-append > .input-group-text, .input-group-prepend > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0rem;
  }
}

.sp-container .input-group-lg > .input-group-prepend > button, .input-group-lg > .input-group-append > .btn, .sp-container .input-group-lg > .input-group-append > button {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0rem;
}

.input-group-sm > {
  .form-control:not(textarea), .custom-select {
    height: calc(1.5em + 0.5rem + 2px);
  }

  .form-control, .custom-select, .input-group-prepend > .input-group-text, .input-group-append > .input-group-text, .input-group-prepend > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.76562rem;
    line-height: 1.5;
    border-radius: 0rem;
  }
}

.sp-container .input-group-sm > .input-group-prepend > button, .input-group-sm > .input-group-append > .btn, .sp-container .input-group-sm > .input-group-append > button {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0rem;
}

.input-group-lg > .custom-select, .input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn, .sp-container .input-group > .input-group-prepend > button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > {
  .input-group-prepend > .input-group-text, .input-group-append:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.sp-container .input-group > .input-group-append:not(:last-child) > button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append {
  &:not(:last-child) > .input-group-text, &:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.sp-container .input-group > .input-group-append:last-child > button:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append {
  &:last-child > .input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.sp-container .input-group > .input-group-append > button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > {
  .input-group-append > .input-group-text, .input-group-prepend:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.sp-container .input-group > .input-group-prepend:not(:first-child) > button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .input-group-prepend {
  &:not(:first-child) > .input-group-text, &:first-child > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.sp-container .input-group > .input-group-prepend:first-child > button:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

input.parsley-error:focus, textarea.parsley-error:focus, input.parsley-success:focus, textarea.parsley-success:focus {
  box-shadow: none !important;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.parsley-checkbox {
  &.parsley-error {
    padding: 10px;
    border: 1px solid $danger;
  }

  &.parsley-success {
    padding: 10px;
    border: 1px solid $success;
  }
}

.parsley-errors-list {
  color: $danger;
  list-style: none;
  font-size: 12px;
  padding: 0;
  margin-bottom: 0;

  li {
    margin: 5px 0 0;
  }
}

.parsley-style-1 {
  .parsley-input {
    &.parsley-error .form-control {
      border-color: $danger;
      border-radius: 0;
    }

    &.parsley-success .form-control {
      border-color: $success;
    }
  }

  .parsley-checkbox.parsley-error {
    border: 1px solid $danger;
  }

  .parsley-select.parsley-error .select2-container--default .select2-selection--single {
    border-radius: 0;
  }

  .parsley-errors-list.filled {
    color: $danger;
    padding: 7px 10px;

    li:first-child {
      margin-top: 0;
    }
  }
}
.iti__selected-flag:focus{
     outline:0;
}
.form-control:focus{
     outline:0;
}