/*---  Content  ---*/

.main-content-body-chat {
  flex-direction: column;
  display: flex;
}

.main-content {
  .container, .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.main-content-app {
  padding-top: 20px;

  .container, .container-fluid {
    height: 100%;
    padding: 0;
  }
}

.main-content-left {
  width: 100%;
  position: relative;
  padding: 0 20px 20px;
  flex-shrink: 0;
  display: none;
}

.main-content-header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .main-logo {
    flex: 1;
    text-align: center;
  }
}

.main-content-body {
  flex: 1;
}

.main-content-right {
  padding-left: 25px;
  margin-left: 25px;
  width: 200px;
  display: none;
}

.main-content-breadcrumb {
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $muted;
  margin-bottom: 10px;

  span {
    position: relative;

    + span::before {
      content: '\f3d1';
      font-family: 'Ionicons';
      font-size: 12px;
      font-weight: 300;
      display: inline;
      margin: 0 5px;
      color: #b4bdce;
      line-height: 0;
      position: relative;
      top: 1px;
    }

    &:last-child {
      color: #596882;
    }
  }
}

.main-content-title {
  color: $color;
  font-weight: 500;
  font-size: 32px;
  text-indent: -1px;
  line-height: 1;
  position: relative;
  margin-bottom: 20px;
}

.main-content-label, .card-table-two .card-title, .card-dashboard-eight .card-title {
  color: $color;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 10px;
  letter-spacing: .2px;
}

.main-content-label-sm {
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  color: #596882;
  letter-spacing: .5px;
}

.main-content-text {
  font-size: 12px;
  line-height: 1.4;
  display: block;
  color: $muted;
}

.main-content-choose-demo {
  position: relative;
  z-index: 5;
  background-color: #0f0373;
  color: rgba(255, 255, 255, 0.5);
  padding: 0;

  .container {
    display: block;
    padding: 20px;
  }

  .title-label {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 10px;
    color: $primary;
    margin-bottom: 3px;
  }

  .title {
    font-size: 20px;
    font-weight: 400;
    color: $white;
    letter-spacing: -.5px;
    margin-bottom: 10px;
  }

  .title-text {
    font-weight: 300;
    margin-bottom: 20px;
  }

  .card {
    border-width: 0;
    margin-bottom: 10px;
    height: 200px;
    overflow: hidden;
    position: relative;

    img {
      transition: all 0.2s ease-in-out;
    }

    figure {
      margin-bottom: 0;

      &:hover img, &:focus img {
        transform: scale(1.75, 1.75);
      }

      &:hover figcaption, &:focus figcaption {
        opacity: 1;
      }
    }

    figcaption {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(25, 4, 190, 0.75);
      opacity: 0;
      transition: all 0.2s ease-in-out;

      .btn, .sp-container button {
        text-transform: uppercase;
        letter-spacing: 1.5px;
        font-size: 11px;
        font-weight: 500;
        min-height: inherit;
        padding: 10px 20px;
        border-radius: 2px;
      }
    }
  }
}

.sp-container .main-content-choose-demo .card figcaption button {
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 11px;
  font-weight: 500;
  min-height: inherit;
  padding: 10px 20px;
  border-radius: 2px;
}

.main-content-choose-demo {
  .card.coming-soon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color;
    background-color:$border;

    .typcn {
      line-height: .9;
      font-size: 48px;
      margin-bottom: 10px;
    }

    h6 {
      margin-bottom: 0;
      text-transform: uppercase;
      font-weight: 800;
      letter-spacing: 1px;
    }
  }

  .main-content-label, .card-table-two .card-title {
    color: $muted;
    margin-bottom: 5px;
    letter-spacing: 1px;
  }
}

.card-table-two .main-content-choose-demo .card-title, .main-content-choose-demo .card-dashboard-eight .card-title, .card-dashboard-eight .main-content-choose-demo .card-title {
  color: $muted;
  margin-bottom: 5px;
  letter-spacing: 1px;
}

.main-content-choose-demo .main-content-title {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0;
  color: $white;
}

.main-content-left-components {
  border-right: 1px solid$border;

  .component-item {
    label {
      display: block;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: .5px;

      ~ label {
        margin-top: 30px;
      }
    }

    .nav-link {
      padding: 0;
      display: block;
      font-size: 13px;
      color: #596882;

      + .nav-link {
        margin-top: 5px;
      }

      &:hover {
        color: $primary;
      }

      &.active {
        color: $primary;
        font-weight: 500;
      }
    }
  }
}



.main-content-left-invoice {
  display: block;
  padding: 0;

  .main-content-breadcrumb, .main-content-title {
    padding-left: 20px;
  }
}

.main-content-body-invoice {
  position: relative;
  display: none;
}

.main-content-mail {
  .container, .container-fluid {
    padding: 20px 0 0;
  }
}

.main-content-left-mail .btn-compose {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  padding: 0 20px;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
}

.main-content-profile {
  flex: 1;
}

.main-content-left-profile {
  padding-left: 0;
  padding-right: 0;
  display: block;
  border-bottom: 1px solid$border;
  padding-bottom: 25px;
  width: auto;
}

.main-content-body-profile {
  .nav {
    flex-direction: column;
    padding: 20px 20px 20px 20px;
    border-bottom: 1px solid$border;
  }

  .main-nav-line .nav-link {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    color: #3b4863;

    &.active {
      font-weight: 500;
      color: $primary;

      &::before {
        background-color: $primary;
      }
    }
  }
}

.main-content-dashboard-ten {
  padding: 0;
  min-height: 100vh;
  background-color:$border;

  .main-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: $white;
  }

  .main-header-center {
    margin-left: 0;
  }

  .main-content-body {
    margin-top: 64px;
  }

  .main-content-body-left {
    flex: 1;
    padding: 20px;
  }

  .main-content-body-right {
    background-color: #f4f5fb;
    padding: 20px;
    display: none;
  }

  .main-content-title {
    letter-spacing: -.8px;
  }

  .card {
    border-width: 0;
    box-shadow: 0 0 6px rgba(28, 39, 60, 0.12);
  }

  .main-footer {
    margin-top: auto;
  }
}


@media (max-width: 575px) {
  .main-content-left-show {
    overflow: hidden;

    .main-content-body {
      display: none;
    }
  }
}
@media (min-width: 576px) {
.main-content-body-profile .nav {
    flex-direction: row;
    align-items: center; 
    padding-bottom: 10px;

    .main-nav-line .nav-link.active::before {
      bottom: -11px;
    }
  }

  .main-content-choose-demo {
    .container {
      padding: 40px;
    }

    .title-text {
      margin-bottom: 40px;
    }

    .card {
      height: 155px;
    }
  }

  .main-content-left-mail {
    width: 300px;
  }

  .main-content-left {
    width: 280px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .main-content-body-profile .main-nav-line .nav-link {
    &.active::before {
      width: auto;
      top: auto;
      left: 0;
      right: 0;
      height: 2px;
    }

    + .nav-link {
      margin-top: 0;
      margin-left: 20px;
    }
  }
}
@media (min-width: 768px) {
  .main-content-choose-demo {
    .title-label {
      font-size: 11px;
    }

    .title {
      font-size: 30px;
    }

    .title-text {
      font-size: 16px;
    }

    .card {
      height: 215px;
      margin-bottom: 15px;
    }

    .main-content-title {
      font-size: 17px;
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .main-content-body-show {
    .main-header-contacts .main-header-menu-icon {
      display: flex;
    }

    .main-content-left-contacts {
      display: block;
    }
  }
}
@media (max-width: 991.98px) {
  .main-content-left-show .main-content-left {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: $white;
    z-index: 800;
    border-right: 1px solid$border;
    box-shadow: 0 0 3px rgba(28, 39, 60, 0.1);
    overflow-y: auto;
  }

  .main-content-body-show {
    .main-header {
      .main-header-menu-icon {
        display: none;
      }

      .main-header-left .main-header-arrow {
        display: block;
      }
    }

    .main-content-left {
      display: none;
    }

    .main-content-body {
      display: block;
    }
  }

  .main-content .container {
    max-width: none;
  }

  .main-content-body-show {
    .main-header-menu-icon {
      display: none;
    }

    .main-header-left .main-header-arrow {
      display: block;
    }

    .main-content-left-chat {
      display: none;
    }

    .main-content-body-chat {
      display: flex;
    }
  }

  .main-content-left-invoice {
    width: 100%;
  }

  .main-content-profile {
    .container, .container-fluid {
      display: block;
    }
  }
}
@media (min-width: 992px) {
  
  .main-content-app {
    height: calc(100vh - 104px);
    padding-top: 30px;
  }

  .main-content-left {
    display: block;
    padding: 0;
  }
   
   .main-content .container {
    padding: 0;
  }
  
  .main-content-header {
    display: none;
  }

  .main-content-right {
    display: block;
  }

  .main-content-title {
    margin-bottom: 40px;
  }

  .main-content-choose-demo {
    .container {
      padding: 100px 40px;
    }

    .title-text {
      margin-bottom: 60px;
    }
  }

  .main-content-calendar {
    .container, .container-fluid {
      padding-top: 0;
    }
  }

  .main-content-left-calendar {
    width: 230px;
  }

  .main-content-body-calendar {
    padding-left: 25px;
  }

  .main-content-left-contacts {
    .main-content-breadcrumb, .main-content-title {
      padding-left: 0;
    }
  }

  .main-content-body-contacts {
    display: block;
    overflow-y: auto;
  }

  .main-content-contacts {
    height: calc(100vh - 60px);
  }

  .main-content-left-invoice {
    border-right: 1px solid$border;

    .main-content-breadcrumb, .main-content-title {
      padding-left: 0;
    }
  }

  .main-content-body-invoice {
    display: block;
  }

  .main-content-mail {
    .container, .container-fluid {
      padding: 0;
    }
  }

  .main-content-left-mail {
    width: 230px;
    display: block;
    padding: 0;
    border-right: 1px solid$border;

    .btn-compose {
      margin-right: 25px;
    }
  }

  .main-content-left-profile {
    width: 270px;
    padding-right: 20px;
    padding-bottom: 0;
    border-right: 1px solid$border;
    border-bottom: 0;
  }
  .main-content-dashboard-ten {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 64px;

    .main-header {
      left: 64px;

      .container-fluid {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
  .main-content-body-profile .nav {
    padding-left: 20px;
  }
  
}
@media (min-width: 1200px) {
  .main-content-body-profile .nav {
    padding-left: 25px;
  }

  .main-content-choose-demo {
    .container {
      padding: 100px 0;
    }

    .card {
      height: 255px;
    }
  }

  .main-content-left-profile {
    padding-right: 25px;
  }

  .main-content-dashboard-ten {
    .main-content-body {
      display: flex;
    }

    .main-content-body-right {
      display: block;
      width: 280px;
      box-shadow: 0 0 6px rgba(28, 39, 60, 0.1);
    }
  }
}
@media (min-width: 1300px) {
  .main-content-dashboard-ten .main-content-body-right {
    width: 300px;
  }
}

@media (prefers-reduced-motion: reduce) {
  .main-content-choose-demo .card {
    img, figcaption {
      transition: none;
    }
  }
}


.main-content-left-chat {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.main-content-left-contacts {
  width: 100%;
  display: block;
  padding: 0;

  .main-content-breadcrumb, .main-content-title {
    padding-left: 20px;
  }
}


.main-nav-line-chat {
  border-bottom: 1px solid$border;
  padding-bottom: 5px;
  flex-shrink: 0;
  flex-direction: row;
  padding-left: 20px;

  .nav-link {
    font-size: 13px;
    font-weight: 500;
    color: #3b4863;
    text-transform: uppercase;

    &:hover, &:focus, &:active {
      outline: none;
    }

    + .nav-link {
      margin-top: 0;
      margin-left: 30px;
    }

    &.active {
      color: $primary;

      &::before {
        top: auto;
        left: 0;
        right: 0;
        width: auto;
        bottom: -6px;
        background-color: $primary;
      }
    }
  }
}