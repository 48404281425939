/*---  Navigation  ---*/

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;

  &:hover, &:focus {
    text-decoration: none;
  }

  &.disabled {
    color: $muted;
    pointer-events: none;
    cursor: default;
  }
}
.nav-tabs {
  border-bottom: 1px solid$border;
  border-bottom-width: 0;

  .nav-item {
    margin-bottom: -1px;
  }

  .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: rgba(255, 255, 255, 0.3);
    border-width: 0;
    border-radius: 0;
    padding: 10px 15px;
    line-height: 1.428;
    color: #596882;

    &:hover, &:focus {
      border-color:$border$border$border;
      background-color: rgba(255, 255, 255, 0.5);
      color: $color;
    }

    &.disabled {
      color: $muted;
      background-color: transparent;
      border-color: transparent;
    }

    &.active {
      color: #596882;
      background-color: $white;
      border-color:$border$border $white;
    }
  }

  .nav-item.show .nav-link {
    color: #596882;
    background-color: $white;
    border-color:$border$border $white;
  }

  .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.nav-pills {
  .nav-link {
    border-radius: 3px;
    color: #596882;

    &.active {
      color: $white;
      background-color: $primary;
    }
  }

  .show > .nav-link {
    color: $white;
    background-color: $primary;
  }
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.navbar > {
  .container, .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.33594rem;
  padding-bottom: 0.33594rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  line-height: inherit;
  white-space: nowrap;

  &:hover, &:focus {
    text-decoration: none;
  }
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  .nav-link {
    padding-right: 0;
    padding-left: 0;
  }

  .dropdown-menu {
    position: static;
    float: none;
  }
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 3px;

  &:hover, &:focus {
    text-decoration: none;
  }
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;

  > {
    .container, .container-fluid {
      padding-right: 0;
      padding-left: 0;
      flex-wrap: nowrap;
    }
  }

  .navbar-nav {
    flex-direction: row;

    .dropdown-menu {
      position: absolute;
    }

    .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }

  .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-toggler {
    display: none;
  }
}

.navbar-light {
  .navbar-brand {
    color: rgba(0, 0, 0, 0.9);

    &:hover, &:focus {
      color: rgba(0, 0, 0, 0.9);
    }
  }

  .navbar-nav {
    .nav-link {
      color: rgba(0, 0, 0, 0.5);

      &:hover, &:focus {
        color: rgba(0, 0, 0, 0.7);
      }

      &.disabled {
        color: rgba(0, 0, 0, 0.3);
      }
    }

    .show > .nav-link, .active > .nav-link {
      color: rgba(0, 0, 0, 0.9);
    }

    .nav-link {
      &.show, &.active {
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }

  .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  .navbar-text {
    color: rgba(0, 0, 0, 0.5);

    a {
      color: rgba(0, 0, 0, 0.9);

      &:hover, &:focus {
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }
}

.navbar-dark {
  .navbar-brand {
    color: $white;

    &:hover, &:focus {
      color: $white;
    }
  }

  .navbar-nav {
    .nav-link {
      color: rgba(255, 255, 255, 0.5);

      &:hover, &:focus {
        color: rgba(255, 255, 255, 0.75);
      }

      &.disabled {
        color: rgba(255, 255, 255, 0.25);
      }
    }

    .show > .nav-link, .active > .nav-link {
      color: $white;
    }

    .nav-link {
      &.show, &.active {
        color: $white;
      }
    }
  }

  .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  .navbar-text {
    color: rgba(255, 255, 255, 0.5);

    a {
      color: $white;

      &:hover, &:focus {
        color: $white;
      }
    }
  }
}

.main-navbar-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 900;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s;
  width: auto !important;
}

.main-navbar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 240px;
  overflow-y: auto;
  background-color: $white;
  z-index: 1000;
  visibility: hidden;
  transform: translateX(-240px);

  > {
    .container, .container-fluid {
      display: flex;
      flex-direction: column;
    }
  }

  .nav {
    flex-direction: column;
    padding: 20px;
    margin-bottom: 20px;
  }

  .nav-label {
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    color: $muted;
    letter-spacing: 1px;
    display: block;
    margin-bottom: 10px;
  }

  .nav-item {
    position: relative;

    &.active .nav-link, &:hover .nav-link {
      color: $primary;

      &.with-sub::after {
        color: $primary;
      }
    }

    &.show> .nav-sub {
      display: block;
    }
  }

  .nav-item-mega {
    position: static;
  }

  .nav-link {
    padding: 0;
    color: $color;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    display: flex;
    align-items: center;
    height: 42px;
    outline: none;

    i {
      font-size: 18px;
      margin-right: 10px;

      &.typcn {
        line-height: 1;
        width: 20px;

        &::before {
          width: auto;
        }
      }
    }

    &.with-sub {
      &::after {
        content: '\f3d0';
        font-family: 'Ionicons';
        font-size: 11px;
        font-weight: 400;
        display: inline-block;
        position: relative;
        margin-left: auto;
        color: $muted;
      }

      &::before {
        content: '';
        position: absolute;
        top: 31px;
        left: 50%;
        margin-left: -7px;
        width: 15px;
        height: 15px;
        border: 1px solid transparent;
        border-top-color: rgba(61, 119, 180, 0.2);
        border-left-color: rgba(61, 119, 180, 0.2);
        transform: rotate(45deg);
        background-color: $white;
        z-index: 901;
        display: none;
      }
    }
  }

  .nav-sub {
    display: none;
    border-top: 1px dotted$border;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .nav-sub-item {
    position: relative;
    display: block;
    margin-left: 0;

    > .nav-sub {
      left: 176px;
      top: -10px;
      display: none;
    }

    &.show > .nav-sub {
      display: block;
    }

    &.active > .nav-sub-link {
      color: $primary;

      &:before {
        color: $primary;
      }
    }
  }

  .nav-sub-link {
    position: relative;
    font-size: .875rem;
    color: $color;
    display: flex;
    align-items: center;
    height: 34px;

    &:hover, &:focus {
      color: $primary;
      outline: none;
    }

    &.with-sub {
      justify-content: space-between;

      &::after {
        content: '\f3d0';
        font-family: 'Ionicons';
        font-size: 11px;
        font-weight: 400;
        display: inline-block;
        position: relative;
        margin-left: auto;
        color: $muted;
      }
    }
  }
  
  .nav-item .nav-sub {
    &.nav-sub-mega li:not(.nav-sub-item) {
      margin-bottom: 15px !important;
    }

    li:not(.nav-sub-item) {
      display: block;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      color: $color;
      letter-spacing: .5px;
      margin-bottom: 5px !important;
      margin-top: 5px !important;

      &:first-of-type {
        margin-top: 0;
      }
    }
   }
	
  .nav-sub-mega {
    .nav li:not(.nav-sub-item) {
      display: block;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      color: $color;
      letter-spacing: .5px;
      margin-bottom: 10px;
      margin-top: 30px;
      margin-left: 30px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    .nav-sub-item + .nav-sub-item {
      padding-top: 7px;
      margin-top: 7px;
    }

    .nav-sub-link {
      height: auto;
    }
  }
}

.main-navbar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 1px solid$border;
  height: 60px;
}

.main-navbar-search {
  position: relative;
  padding: 0 20px;
  border-bottom: 1px solid$border;

  .form-control {
    border-width: 0;
    padding: 0;

    &:focus {
      box-shadow: none !important;
      border-color:$border;
    }
  }

  .btn, .sp-container button {
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    height: 38px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    line-height: 1;
  }
}

.sp-container .main-navbar-search button {
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  height: 38px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  line-height: 1;
}

.nav-link {
  &:hover, &:focus, &:active {
    outline: none;
  }
}

.nav-pills .nav-link {
  &:hover, &:focus {
    color: $color;
  }

  &.active {
    border-radius: 2px;
    background-color: $primary;

    &:hover, &:focus {
      color: $white;
    }
  }
}

.nav-dark .nav-link {
  color: $muted;

  &:hover, &:focus {
    color: $white;
  }
}

.nav-tabs .nav-link {
  + .nav-link {
    margin-left: 3px;
  }

  &.active {
    background-color: $white;
    color: $color;
    font-weight: 500;
    letter-spacing: -.1px;
  }
}

.main-navbar-two {
  .nav-item {
    &.active {
      position: relative;

      &::before {
        content: '';
        position: absolute;
      }
    }

    &.show .nav-link::before {
      display: none;
    }
  }

  .nav-link {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
  }

  .nav-sub {
    border-width: 1px;
    top: 39px;
    border-top-width: 0;
  }

  .nav-sub-item > .nav-sub {
    left: 178px;
    top: -8px;
    border-top-width: 1px;
  }

  .nav-sub-mega {
    top: 58px;
  }
}

.main-navbar-three {
  .nav-item {
    display: block;

    + .nav-item {
      border-left-width: 0;
      padding-left: 0;
    }

    &.show .nav-link::before {
      display: none;
    }

    &.active .nav-link {
      color: $primary;
      font-weight: 700;
    }
  }

  .nav-link {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    color: #3b4863;
    position: relative;

    &:hover, &:focus {
      color: $color;
    }
  }

  .nav-sub {
    top: 35px;
    border-width: 0;
    padding-bottom: 5px;
  }

  .nav-sub-item {
    + .nav-sub-item {
      border-top: 0;
    }

    .nav-sub {
      top: -8px;
      left: 182px;
    }
  }

  .nav-sub-link {
    height: 30px;
  }
}

.main-nav .nav-link {
  display: block;
  color: #596882;
  padding: 0;
  position: relative;
  line-height: normal;

  &:hover, &:focus {
    color: $color;
  }

  + .nav-link {
    padding-top: 12px;
    margin-top: 12px;
  }

  &.active {
    color: $primary;
  }
}

.main-nav-column {
  flex-direction: column;

  .nav-link {
    padding: 0;
    height: 38px;
    color: $color;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    i {
      font-size: 24px;
      line-height: 0;
      width: 24px;
      margin-right: 12px;
      text-align: center;
      transition: all 0.2s ease-in-out;
      line-height: .9;

      &:not([class*=' tx-']) {
        color: $muted;
      }
    }

    span {
      font-weight: 400;
      font-size: 11px;
      margin-left: auto;
    }

    &:hover, &:focus, &:hover i:not([class*=' tx-']), &:focus i:not([class*=' tx-']) {
      color: $color;
    }

    &.active {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 6px;
        bottom: 6px;
        left: -28px;
        width: 3px;
        background-color: $primary;
        border-radius: 3px;
        display: none;
      }

      color: $primary;

      &:hover, &:focus, i, &:hover i, &:focus i {
        color: $primary;
      }
    }
  }

  &.sm .nav-link {
    font-size: 0.875rem;
    font-weight: 400;
    padding: 10px 0;

    i {
      font-size: 21px;
    }
  }
}

.main-nav-dark .nav-link {
  color: rgba(255, 255, 255, 0.7);

  &:hover, &:focus {
    color: $white;
  }

  + .nav-link {
    border-color: #596882;
  }

  &.active {
    color: $primary;
  }
}

.main-nav-colored-bg .nav-link {
  + .nav-link {
    border-color: rgba(255, 255, 255, 0.4);
  }

  &.active {
    color: $white;
  }
}

.main-nav-line {
  position: relative;

  .nav-link {
    padding: 0;
    color: #596882;
    position: relative;

    &:hover, &:focus {
      color: $color;
    }

    + .nav-link {
      margin-top: 15px;
    }

    &.active {
      color: $color;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -20px;
        width: 2px;
        background-color: $color;
      }
    }
  }

  &.main-nav-dark .nav-link {
    color: rgba(255, 255, 255, 0.7);

    &:hover, &:focus {
      color: $white;
    }

    &.active {
      color: $white;

      &::before {
        background-color: $white;
      }
    }
  }
}

.main-nav-tabs {
  padding: 15px 15px 0;
  background-color:$border;

  .lSSlideOuter {
    position: relative;
    padding-left: 32px;
    padding-right: 35px;
  }

  .lSSlideWrapper {
    overflow: visible;
  }

  .lSAction > a {
    display: block;
    height: 40px;
    top: 16px;
    opacity: 1;
    background-color: #b4bdce;
    background-image: none;

    &:hover, &:focus {
      background-color: #a5afc4;
    }

    &::before {
      font-family: 'Ionicons';
      font-size: 18px;
      position: absolute;
      top: -4px;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.lSPrev {
      left: -32px;

      &::before {
        content: '\f3cf';
      }
    }

    &.lSNext {
      right: -35px;

      &::before {
        content: '\f3d1';
      }
    }

    &.disabled {
      background-color:$border;
      color: $white;
    }
  }

  .lightSlider {
    display: flex;
  }

  .tab-item {
    flex-shrink: 0;
    display: block;
    float: none;
    min-width: 150px;
  }

  .tab-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    line-height: 1.428;
    color: #596882;
    white-space: nowrap;
    background-color:$border;

    &:hover, &:focus {
      background-color: #f1f2f9;
    }

    &.active {
      background-color: $white;
      color: $color;
      font-weight: 500;
    }
  }
}

.main-nav-calendar-event .nav-link {
  font-size: 13px;
  position: relative;

  i {
    font-size: 21px;
    position: relative;
    margin-right: 10px;
    top: -.5px;
  }

  &.exclude {
    color: $muted;

    i {
      color: $muted;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      border-top: 1px solid $muted;
    }
  }
}


@media print {
  .navbar {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .navbar-expand-sm > {
    .container, .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
}
@media (min-width: 576px) {
.main-content-body-profile .main-nav-line .nav-link.active::before {
    bottom: -11px;
}
@media (max-width: 767.98px) {
  .navbar-expand-md > {
    .container, .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;

    .navbar-nav {
      flex-direction: row;

      .dropdown-menu {
        position: absolute;
      }

      .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }

    > {
      .container, .container-fluid {
        flex-wrap: nowrap;
      }
    }

    .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }

    .navbar-toggler {
      display: none;
    }
  }

  .nav-tabs .nav-link {
    padding: 10px 20px;
  }

  .main-nav .nav-link + .nav-link {
    padding-top: 0;
    margin-top: 0;
    border-top: 0;
    padding-left: 15px;
    margin-left: 15px;
  }

  .main-nav-line .nav-link {
    + .nav-link {
      margin-top: 0;
      margin-left: 30px;
    }

    &.active::before {
      top: auto;
      bottom: -18px;
      left: 0;
      right: 0;
      height: 2px;
      width: auto;
    }
  }

  .main-nav {
    align-items: center;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > {
    .container, .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .main-navbar-show {
    overflow: inherit;

    .main-navbar-backdrop {
      visibility: visible;
      opacity: 1;
    }

    .main-navbar {
      transform: translateX(0);
      visibility: visible;
    }
  }

  .main-navbar {
    > {
      .container, .container-fluid {
        padding: 0;
      }
    }

    .nav-sub-item > .nav-sub > .nav-sub-item {
      margin-left: 37px;

      > .nav-sub-link {
        padding-left: 10px;
      }
    }

    .nav-sub-mega {
      .container, .container-fluid {
        max-width: none;
      }

      .nav + .nav .nav-sub-item:first-child {
        margin-top: 6px;
        padding-top: 6px;
      }
    }
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;

    .navbar-nav {
      flex-direction: row;

      .dropdown-menu {
        position: absolute;
      }

      .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }

    > {
      .container, .container-fluid {
        flex-wrap: nowrap;
      }
    }

    .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }

    .navbar-toggler {
      display: none;
    }
  }

  .main-navbar {
    position: relative;
    top: auto;
    left: auto;
    bottom: auto;
    width: auto;
    padding: 5px;
    border-right: 0;
    overflow-y: inherit;
    display: block;
    border-bottom: 0;
    visibility: visible;
    transform: none;
    z-index: auto;
    z-index: 10;
    box-shadow: 0 2px 17px 1px rgba(162, 169, 204, 0.24);
    border-bottom: 1px solid transparent;

    > {
      .container, .container-fluid {
        flex-direction: row;
        align-items: center;
        height: 48px;
      }

      .container {
        padding: 0;
      }

      .container-fluid {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .nav {
      flex-direction: row;
      align-items: center;
      margin-bottom: 0;
      padding: 0;
    }

    .nav-label {
      display: none;
    }

    .nav-item {
      + .nav-item {
        border-top: 0;
        margin-left: 15px;
        padding-left: 15px;
      }

      &.show .nav-link::before {
        display: block;
      }
    }

    .nav-link {
      height: auto;
      font-size: 14px;

      i {
        margin-right: 8px;
        font-size: 18px;

        &.typcn {
          line-height: .9;
          width: auto;
        }
      }

      &.with-sub::after {
        margin-left: 4px;
      }
    }

    .nav-sub {
      position: absolute;
      top: 39px;
      left: 0;
      width: 200px;
      background-color: $white;
      border: 1px solid transparent;
      border-top: 1px solid$border;
      padding: 8px 20px;
      z-index: 900;
      box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
    }
  }

  .nav-item-mega .nav-sub {
    box-shadow: none;
  }

  .main-navbar {
    .nav-sub .container {
      box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
    }

    .nav-sub-item {
      margin-left: 0;
    }

    .nav-sub-link {
      font-size: .8125rem;
      height: 36px;

      &.with-sub::after {
        margin-left: 4px;
        content: '\f3d1';
        font-size: 12px;
      }
    }

    .nav-sub-mega {
      padding: 0 25px;
      border-top: 0;

      .container, .container-fluid {
        display: flex;
        background-color: $white;
        border: 1px solid transparent;
        border-top: 1px solid$border;
      }

      .container > div, .container-fluid > div {
        display: flex;
        padding: 20px;
        flex: 1;
        margin-top: 0;
      }

      .container > div + div, .container-fluid > div + div {
        margin-top: 0;
        border-left: 1px solid$border;
      }

      .nav + .nav {
        margin-left: 20px;
        margin-top: 31px;
        padding-top: 0;
        border-top: 0;
      }
    }

    .nav-item .nav-sub li:not(.nav-sub-item) {
      margin-bottom: 15px;
      margin-left: 0;
    }
  }

  .main-navbar-header, .main-navbar-search {
    display: none;
  }

  .main-navbar-three {
    .nav-item + .nav-item {
      margin-left: 25px;
    }

    .nav-link {
      display: block;

      i {
        display: none;
      }
    }

    .nav-sub {
      box-shadow: 0 0 0 2px rgba(180, 189, 206, 0.5);
      padding-bottom: 8px;
    }

    .nav-sub-link {
      height: 36px;
    }
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;

    .navbar-nav {
      flex-direction: row;

      .dropdown-menu {
        position: absolute;
      }

      .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }

    > {
      .container, .container-fluid {
        flex-wrap: nowrap;
      }
    }

    .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }

    .navbar-toggler {
      display: none;
    }
  }

  .main-navbar {
    .nav-item + .nav-item {
      margin-left: 20px;
      padding-left: 20px;
    }

    .nav-sub-mega {
      .container > div, .container-fluid > div {
        padding: 20px 25px;
      }

      .nav {
        min-width: 140px;

        + .nav {
          margin-left: 25px;
        }
      }
    }
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > {
    .container, .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .main-nav-column .nav-link i, .main-navbar-backdrop, .main-navbar {
    transition: none;
  }
}
.main-navbar .nav-sub-mega {
    top: 58px;
    left: 0;
    right: 0;
    background-color: transparent;
    border: 0;
    width: auto;
    pointer-events: none;
}
.main-navbar .nav-sub-mega .nav {
    min-width: 140px;
    flex-direction: column;
    align-items: stretch;
    padding: 0;
    margin: 0;
}
.main-navbar .nav-sub-mega .container, .main-navbar .nav-sub-mega .container-fluid {
  padding: 0;
  pointer-events: auto;
  }
}
