/*---  Ratings  ---*/

.main-rating-value {
  font-size: 40px;
  font-weight: 400;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: $color;
  margin-bottom: 0;
  letter-spacing: -.5px;
  line-height: .7;
}

.main-rating-label {
  margin-bottom: 10px;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: .5px;
}