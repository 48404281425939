/*---  Popover  ---*/

.popover {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1060;
	display: block;
	max-width: 276px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: 0.76562rem;
	word-wrap: break-word;
	background-color: $white;
	background-clip: padding-box;
	border: 1px solid$border;
	border-radius: 0.3rem;
	font-size: 12px;
	padding: 0;
	border-radius: 0;
}
.popover .arrow {
	position: absolute;
	display: block;
	width: 1rem;
	height: 0.5rem;
	margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
	position: absolute;
	display: block;
	content: "";
	border-color: transparent;
	border-style: solid;
}
.popover-header {
	padding: 0.5rem 0.75rem;
	margin-bottom: 0;
	font-size: 0.875rem;
	background-color: #f7f7f7;
	border-bottom: 1px solid #ebebeb;
	border-top-left-radius: calc(0.3rem - 1px);
	border-top-right-radius: calc(0.3rem - 1px);
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 600;
	color: #3b4863;
	letter-spacing: 0.5px;
	padding: 12px 15px;
	background-color: $white;
	border-color:$border;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.popover-header:empty {
	display: none;
}
.popover-body {
	padding: 0.5rem 0.75rem;
	color:$color;
	padding: 15px;
}
.popover-static-demo {
	background-color:$border;
	padding-top: 40px;
	padding-bottom: 40px;
	text-align: center;
}
.popover-static-demo .popover {
	z-index: 0;
	opacity: 1;
	position: relative;
	display: inline-block;
	margin: 0 10px;
}
.popover-static-demo .bs-popover-top .arrow, .popover-static-demo .bs-popover-auto[x-placement^="top"] .arrow, .popover-static-demo .bs-popover-bottom .arrow, .popover-static-demo .bs-popover-auto[x-placement^="bottom"] .arrow {
	left: 50%;
	margin-left: -5px;
}
.popover-static-demo .bs-popover-left, .popover-static-demo .bs-popover-auto[x-placement^="left"], .popover-static-demo .bs-popover-right, .popover-static-demo .bs-popover-auto[x-placement^="right"] {
	margin-top: 5px;
}
.popover-static-demo .bs-popover-left .arrow, .popover-static-demo .bs-popover-auto[x-placement^="left"] .arrow, .popover-static-demo .bs-popover-right .arrow, .popover-static-demo .bs-popover-auto[x-placement^="right"] .arrow {
	top: 50%;
	margin-top: -5px;
}
.popover-header::before {
	display: none !important;
}
.popover-body p:last-child {
	margin-bottom: 0;
}
.popover-head-primary .popover-header {
	color: $white;
	background-color: $primary;
}
.popover-head-primary.bs-popover-top .arrow, .popover-head-primary.bs-popover-auto[x-placement^="top"] .arrow {
	bottom: -7px;
}
.popover-head-primary.bs-popover-left .arrow, .popover-head-primary.bs-popover-auto[x-placement^="left"] .arrow {
	right: -7px;
}
.popover-head-primary.bs-popover-right .arrow, .popover-head-primary.bs-popover-auto[x-placement^="right"] .arrow {
	left: -7px;
}
.popover-head-primary.bs-popover-bottom .arrow::after, .popover-head-primary.bs-popover-auto[x-placement^="bottom"] .arrow::after, .popover-head-primary.bs-popover-auto[x-placement^="bottom"] .arrow::after {
	border-bottom-color: $primary;
}
.popover-head-secondary .popover-header {
	color: $white;
	background-color: $secondary;
}
.popover-head-secondary.bs-popover-top .arrow, .popover-head-secondary.bs-popover-auto[x-placement^="top"] .arrow {
	bottom: -7px;
}
.popover-head-secondary.bs-popover-left .arrow, .popover-head-secondary.bs-popover-auto[x-placement^="left"] .arrow {
	right: -7px;
}
.popover-head-secondary.bs-popover-right .arrow, .popover-head-secondary.bs-popover-auto[x-placement^="right"] .arrow {
	left: -7px;
}
.popover-head-secondary.bs-popover-bottom .arrow::after, .popover-head-secondary.bs-popover-auto[x-placement^="bottom"] .arrow::after, .popover-head-secondary.bs-popover-auto[x-placement^="bottom"] .arrow::after {
	border-bottom-color: $secondary;
}
.popover-head-primary, .popover-head-primary .popover-header, .popover-head-secondary, .popover-head-secondary .popover-header {
	border: 0;
}
.popover-head-primary .popover-body, .popover-head-secondary .popover-body {
	border: 1px solid rgba(28, 39, 60, 0.2);
	border-top-width: 0;
	border-bottom-right-radius: 2px;
	border-bottom-left-radius: 2px;
}
.popover-head-primary.bs-popover-bottom .arrow::before, .popover-head-primary.bs-popover-auto[x-placement^="bottom"] .arrow::before, .popover-head-primary.bs-popover-auto[x-placement^="bottom"] .arrow::before, .popover-head-secondary.bs-popover-bottom .arrow::before, .popover-head-secondary.bs-popover-auto[x-placement^="bottom"] .arrow::before, .popover-head-secondary.bs-popover-auto[x-placement^="bottom"] .arrow::before {
	display: none;
}
.popover-primary {
	background-color: $primary;
	border-width: 0;
	padding: 15px;
}
.popover-primary .popover-header {
	background-color: transparent;
	border-bottom-width: 0;
	padding: 0 0 15px;
	color: $white;
}
.popover-primary .popover-body {
	padding: 0;
	color: rgba(255, 255, 255, 0.75);
}
.popover-primary .arrow::before {
	display: none;
}
.popover-primary.bs-popover-top .arrow::after, .popover-primary.bs-popover-auto[x-placement^="top"] .arrow::after, .popover-primary.bs-popover-auto[x-placement^="top"] .arrow::after {
	border-top-color: $primary;
}
.popover-primary.bs-popover-bottom .arrow::after, .popover-primary.bs-popover-auto[x-placement^="bottom"] .arrow::after, .popover-primary.bs-popover-auto[x-placement^="bottom"] .arrow::after {
	border-bottom-color: $primary;
}
.popover-primary.bs-popover-left .arrow::after, .popover-primary.bs-popover-auto[x-placement^="left"] .arrow::after, .popover-primary.bs-popover-auto[x-placement^="left"] .arrow::after {
	border-left-color: $primary;
}
.popover-primary.bs-popover-right .arrow::after, .popover-primary.bs-popover-auto[x-placement^="right"] .arrow::after, .popover-primary.bs-popover-auto[x-placement^="right"] .arrow::after {
	border-right-color: $primary;
}
.popover-secondary {
	background-color:$secondary;
	border-width: 0;
	padding: 15px;
}
.popover-secondary .popover-header {
	background-color: transparent;
	border-bottom-width: 0;
	padding: 0 0 15px;
	color: $white;
}
.popover-secondary .popover-body {
	padding: 0;
	color: rgba(255, 255, 255, 0.75);
}
.popover-secondary .arrow::before {
	display: none;
}
.popover-secondary.bs-popover-top .arrow::after, .popover-secondary.bs-popover-auto[x-placement^="top"] .arrow::after, .popover-secondary.bs-popover-auto[x-placement^="top"] .arrow::after {
	border-top-color: $secondary;
}
.popover-secondary.bs-popover-bottom .arrow::after, .popover-secondary.bs-popover-auto[x-placement^="bottom"] .arrow::after, .popover-secondary.bs-popover-auto[x-placement^="bottom"] .arrow::after {
	border-bottom-color: $secondary;
}
.popover-secondary.bs-popover-left .arrow::after, .popover-secondary.bs-popover-auto[x-placement^="left"] .arrow::after, .popover-secondary.bs-popover-auto[x-placement^="left"] .arrow::after {
	border-left-color: $secondary;
}
.popover-secondary.bs-popover-right .arrow::after, .popover-secondary.bs-popover-auto[x-placement^="right"] .arrow::after, .popover-secondary.bs-popover-auto[x-placement^="right"] .arrow::after {
	border-right-color: $secondary;
}
.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
	margin-bottom: 0.5rem;
}
.bs-popover-top>.arrow, .bs-popover-auto[x-placement^="top"]>.arrow {
	bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top>.arrow::before, .bs-popover-auto[x-placement^="top"]>.arrow::before {
	bottom: 0;
	border-width: 0.5rem 0.5rem 0;
	border-top-color:$border;
}
.bs-popover-top>.arrow::after, .bs-popover-auto[x-placement^="top"]>.arrow::after {
	bottom: 1px;
	border-width: 0.5rem 0.5rem 0;
	border-top-color: $white;
}
.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
	margin-left: 0.5rem;
}
.bs-popover-right>.arrow, .bs-popover-auto[x-placement^="right"]>.arrow {
	left: calc((0.5rem + 1px) * -1);
	width: 0.5rem;
	height: 1rem;
	margin: 0.3rem 0;
}
.bs-popover-right>.arrow::before, .bs-popover-auto[x-placement^="right"]>.arrow::before {
	left: 0;
	border-width: 0.5rem 0.5rem 0.5rem 0;
	border-right-color:$border;
}
.bs-popover-right>.arrow::after, .bs-popover-auto[x-placement^="right"]>.arrow::after {
	left: 1px;
	border-width: 0.5rem 0.5rem 0.5rem 0;
	border-right-color: $white;
}
.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
	margin-top: 0.5rem;
}
.bs-popover-bottom>.arrow, .bs-popover-auto[x-placement^="bottom"]>.arrow {
	top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom>.arrow::before, .bs-popover-auto[x-placement^="bottom"]>.arrow::before {
	top: 0;
	border-width: 0 0.5rem 0.5rem 0.5rem;
	border-bottom-color:$border;
}
.bs-popover-bottom>.arrow::after, .bs-popover-auto[x-placement^="bottom"]>.arrow::after {
	top: 1px;
	border-width: 0 0.5rem 0.5rem 0.5rem;
	border-bottom-color: $white;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
	position: absolute;
	top: 0;
	left: 50%;
	display: block;
	width: 1rem;
	margin-left: -0.5rem;
	content: "";
	border-bottom: 1px solid #f7f7f7;
}
.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
	margin-right: 0.5rem;
}
.bs-popover-left>.arrow, .bs-popover-auto[x-placement^="left"]>.arrow {
	right: calc((0.5rem + 1px) * -1);
	width: 0.5rem;
	height: 1rem;
	margin: 0.3rem 0;
}
.bs-popover-left>.arrow::before, .bs-popover-auto[x-placement^="left"]>.arrow::before {
	right: 0;
	border-width: 0.5rem 0 0.5rem 0.5rem;
	border-left-color:$border;
}
.bs-popover-left>.arrow::after, .bs-popover-auto[x-placement^="left"]>.arrow::after {
	right: 1px;
	border-width: 0.5rem 0 0.5rem 0.5rem;
	border-left-color: $white;
}





















